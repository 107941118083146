import React, { useState, useEffect, useCallback } from "react";
import Footer from "./common/Footer";
import SalesHeader from "./common/SalesHeader";
import AMHeader from "./common/AMHeader";
import RMHeader from "./common/RMHeader";
import PRDHeader from "./common/PRDHeader";

import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import Oval from "./loader/CircleLoade";
import { Link, useNavigate } from 'react-router-dom'

export default function HierarchyLogin() {
	const navigate = useNavigate()

  const [rmDatas, setRmDatas] = useState([]);
  const [loading, setLoading] = useState(false);
	const [regionalId, setRegionalId] = useState(0);
	const [areaId, setAreaId] = useState(0);
	const [salesId, setSalesId] = useState(0);
	const [lastSelectedId, setLastSelectedId] = useState(0);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [selectedText, setSelectedText] = useState("");
	const [areaDatas, setAreaDatas] = useState([]);
	const [salesDatas, setSalesDatas] = useState([]);

  let token = localStorage.getItem("token");

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        setLoading(true);
        axios
          .get(
            Constants.baseUrl +
              `user/manage?sortBy=name&userType=RO&limit=100000&page=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                setRmDatas(res.data.output.results);
              }
            } else {
              // swal(res.data.msg);
            }
						setLoading(false);
          });
      } catch (error) {
        console.log(error);
				setLoading(false);
      }
    }
  }, []);

  const handleRegionalChange = (event) => {
    const selectedId = event.target.value;
    setAreaDatas([]);
    setSalesDatas([]);
    setAreaId(0);
    setSalesId(0);
		setSubmitDisabled(false);
    if (selectedId === "0") {
      setSelectedText(`Please Select User to Continue`)
    } else {
			setRegionalId(selectedId);
			setLastSelectedId(selectedId);

			const selectedRegional = rmDatas.find(
				(item) => item.id == selectedId
			);
			const selectedName = selectedRegional ? selectedRegional.name : "";

			setSelectedText(`You have selected Regional Manager ${selectedName} click Login to proceed`);
      getArea(selectedId);
			setSubmitDisabled(true);
    }
  };

  const getArea = async (rId) => {
		setLoading(true);
    try {
      await axios
        .get(Constants.baseUrl + `user/manage/salesUserByParentId/${rId}?userType=SH&sortBy=name&sortAsc=true&limit=15&page=1`, {
          headers: {
            Authorization: `Bearer ${token}`,
						"Accept-Language": localStorage.getItem("langauge"),
						platform: Constants.pt,
						appVersion: Constants.av,
						program: Constants.program,
          },
        })
        .then((res) => {
          if (res.data.code === 10001) {
						setAreaDatas(res.data.output.results);
					} else {
						// swal(res.data.msg)
					}
					setLoading(false);
        });
    } catch (error) {
      console.log(error);
			setLoading(false);
    }
  };

	const handleAreaChange = (event) => {
    const selectedId = event.target.value;
    setSalesDatas([]);
    setSalesId(0);
    if (selectedId === "0") {
			setLastSelectedId(regionalId);
    } else {
			setAreaId(selectedId);
			setLastSelectedId(selectedId);
			const selectedArea = areaDatas.find(
				(item) => item.id == selectedId
			);
			const selectedName = selectedArea ? selectedArea.name : "";

			setSelectedText(`You have selected Area Manager ${selectedName} click Login to proceed`);
      getSales(selectedId);
    }
  };

  const getSales = async (rId) => {
		setLoading(true);
    try {
      await axios
        .get(Constants.baseUrl + `user/manage/salesUserByParentId/${rId}?userType=SALES&sortBy=name&sortAsc=true&limit=30&page=1`, {
          headers: {
            Authorization: `Bearer ${token}`,
						"Accept-Language": localStorage.getItem("langauge"),
						platform: Constants.pt,
						appVersion: Constants.av,
						program: Constants.program,
          },
        })
        .then((res) => {
          if (res.data.code === 10001) {
						setSalesDatas(res.data.output.results);
					} else {
						// swal(res.data.msg)
					}
					setLoading(false);
        });
    } catch (error) {
      console.log(error);
			setLoading(false);
    }
  };

	const handleSalesChange = (event) => {
    const selectedId = event.target.value;
    
    if (selectedId === "0") {
			setLastSelectedId(areaId);
    } else {
			setSalesId(selectedId);
			setLastSelectedId(selectedId);
			const selectedSales = salesDatas.find(
				(item) => item.id == selectedId
			);
			const selectedName = selectedSales ? selectedSales.name : "";
			setSelectedText(`You have selected Sales Executive ${selectedName} click Login to proceed`);
    }
  };

	const handleLogin = async () => {
    
    if (lastSelectedId > 0) {
			try {
				await axios
					.post(
						Constants.baseUrl + `auth/login-id/${lastSelectedId}`,
						{
						},
						{
							headers: {
								"Accept-Language": localStorage.getItem("language"),
								appVersion: Constants.av,
								platform: Constants.pt,
								company: Constants.company,
								program: Constants.program,
							},
						}
					)
					.then((res) => {
						if (res.status === 200) {
							if (res.data.code === 10001) {
								if (
									res.data.output.user.userType == "MEMBER" ||
									res.data.output.user.userType == "SHO"
								) {
									swal({
										text: "You are not authorized, Please contact admin",
										icon: "error",
										timer: 3000,
										buttons: false,
									});
								} else {
									localStorage.setItem("user_id", res.data.output.user.id);
									localStorage.setItem(
										"user_type",
										res.data.output.user.userType
									);
									localStorage.setItem("user_name", res.data.output.user.name);
									localStorage.setItem("user_email", res.data.output.user.email);
									localStorage.setItem(
										"user_mobile",
										res.data.output.user.mobile
									);
									localStorage.setItem("token", res.data.output.access.token);
									// window.location = '/dashboard'
									navigate("/dashboard");
								}
							} else if (res.data.code === 16001) {
								swal({
									text: res.data.msg,
									// icon: 'success',
									timer: 2000,
									buttons: false,
								});
							} else {
								swal({
									text: res.data.msg,
									icon: "error",
									timer: 3000,
									buttons: false,
								});
							}
						} else {
							swal(res.data.msg);
						}
					});
			} catch (error) {
				console.log(error);
			}
    }
  };

	let headerComponent;

  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (
    localStorage.getItem("user_type") === "RO" ||
    localStorage.getItem("user_type") === "HO" || localStorage.getItem("user_type") === "ADMIN"
  ) {
    headerComponent = <RMHeader />;
  } else if (localStorage.getItem("user_type") === "PRD") {
    headerComponent = <PRDHeader />;
  }

  return (
    <>
      <div class="wrapper home-2">
				{loading ? <Oval /> : null}
				{headerComponent}
        <section class="blog-area pt182">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="all-single-blog">
                  <div class="well2" style={{ minHeight: "330px" }}>
                    <div class="row">
                      <div class="col-md-1 col-sm-4 col-xs-12"></div>
                      <div class="col-md-3 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Regional Manager </label>
                          <select class="form-control2" onChange={handleRegionalChange}>
                            <option value="0">Select</option>
                            {rmDatas.length > 0 &&
                              rmDatas.map((item, index) => {
                                return (
                                  <>
                                    <option
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Area Manager </label>
                          <select class="form-control2" onChange={handleAreaChange}>
                            <option value="0">Select</option>
														{areaDatas.length > 0 &&
                              areaDatas.map((item, index) => {
                                return (
                                  <>
                                    <option
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Sales Executive </label>
                          <select class="form-control2" onChange={handleSalesChange}>
                            <option value="0">Select</option>
														{salesDatas.length > 0 &&
                              salesDatas.map((item, index) => {
                                return (
                                  <>
                                    <option
                                      key={index}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-sm-2 col-xs-12"></div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                        <input
                          autocomplete="nope"
                          class="form-control"
                          readonly="true"
                          type="text"
                          value={selectedText}
													disabled={true}
                        />
                      </div>
											{submitDisabled && 
                      <div class="col-md-2 col-sm-4 col-xs-12">
                        <input
                          id="btnsubmit"
                          class="btn login_btn"
                          type="submit"
                          value="Login"
													onClick={handleLogin}
                        />
                      </div>
											}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
