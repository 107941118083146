import React from 'react'
import whatsNewBanner from '../../assets/img/whatsNewBanner.jpg'
import { Link } from 'react-router-dom'
export default function English() {
  return (
    <>
			<section>
				<div className="container">
					<div className='card mt-5'>
						
						<div className='card-body'>
							<div className='row'>
								<div className="col-md-12 mt-2">
									<img src={whatsNewBanner} alt="" className="img-fluid" />
									<h5 className='mt-2'>Hefele India launches the Nagold series of international home appliances</h5>
									<h5 className='mt-2 text-danger'>An exclusive range of appliances especially for Indian requirements </h5>
									<p>Mumbai, 9th October 2014: Hafele today unveiled the NAGOLD series, an exclusive range of home appliances for the Indian kitchen. The attractively priced NAGOLD series will cater to all cooking, refrigeration and cleaning needs amidst an experience of supreme efficiency.</p>
								</div>
							</div>
						</div>
					</div>

					<div className='card mt-5'>
						
						<div className='card-body'>
							<div className='row'>
								<div className="col-md-12 mt-2">
									<img src={whatsNewBanner} alt="" className="img-fluid" />
									<h5 className='mt-2'>Hefele India launches the Nagold series of international home appliances</h5>
									<h5 className='mt-2 text-danger'>An exclusive range of appliances especially for Indian requirements </h5>
									<p>Mumbai, 9th October 2014: Hafele today unveiled the NAGOLD series, an exclusive range of home appliances for the Indian kitchen. The attractively priced NAGOLD series will cater to all cooking, refrigeration and cleaning needs amidst an experience of supreme efficiency.</p>
								</div>
							</div>
						</div>
					</div>

					<div className='card mt-5'>
						
						<div className='card-body'>
							<div className='row'>
								<div className="col-md-12 mt-2">
									<img src={whatsNewBanner} alt="" className="img-fluid" />
									<h5 className='mt-2'>Hefele India launches the Nagold series of international home appliances</h5>
									<h5 className='mt-2 text-danger'>An exclusive range of appliances especially for Indian requirements </h5>
									<p>Mumbai, 9th October 2014: Hafele today unveiled the NAGOLD series, an exclusive range of home appliances for the Indian kitchen. The attractively priced NAGOLD series will cater to all cooking, refrigeration and cleaning needs amidst an experience of supreme efficiency.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  );
}
