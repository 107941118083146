import React, { useState } from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
  const [year] = useState(new Date().getFullYear());
  return (
    <>
      <footer className="">
        <div className="footer-container white-bg">
          <div className="footer-bottom-area">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="copyright-text text-center">
                    <p>
                      Copyright ©
                      {year} <Link to="http://evolvebrands.com/" target="_blank">Evolve Brands Pvt Ltd</Link>, All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
