import React from 'react'
import logo2 from '../../assets/img/logo/logo2.png'
import { Link } from 'react-router-dom'
export default function English() {
  return (
    <>
      <section>
				<div className="container">
					<div className="row">
						<div className="col-md-2 col-sm-12">
							<div className="logo logo1">
								<img src={logo2} alt=""  width={'100px'} height={'111px'} />
							</div>
						</div>
						<div className="col-md-10 col-sm-12"></div>
					</div>
				</div>
			</section>
			<section>
				<div className="container">
					<div className="row myrow">
						<div className="col-md-12">
							<div className="terms-conditions">
								<h3>General Terms and Conditions</h3>
								<ol class="list-terms">
									<li>Please read these Terms and Conditions before accessing or using Hafele Contractor Unnati
										Programme app provided by HAFELE INDIA PRIVATE LIMITED. By accessing or using Hafele
										Contractor Unnati Programme App, you give your free consent, acknowledge and agree that you
										are exclusively allowed to access and use HAFELE Contactor Unnati programme scheme in
										accordance with these Terms and Conditions. You should read the terms &amp; conditions carefully so
										that you understand before availing the HAFELE Contactor Unnati programme scheme.
									</li>
									<li> The terms and conditions (hereinafter referred as ‘terms’) subject to which the Hafele Contractor
										Unnati Programme (hereinafter referred as ‘program’) is made available to you, are as mentioned
										below. Your participation in the program, or any part thereof, shall be deemed to signify your
										intention to be bound by these terms.
									</li>
									<li>  Please note that this program is being made available to all the Contractors, Carpenters,
										Electrician (in the case of Lightings) and Plumber (in case of sanitary solutions) hereinafter
										referred as ‘INFLUENCER’ and is applicable till the program running duration starting 1st March
										2022.
									</li>
									<li>  HAFELE INDIA PRIVATE LIMITED reserves the right to withdraw/suspend/cancel/ amend the
										whole or any part of this program without prior notice. No part of this program or the benefits
										accruing here-from are to be construed as ‘consideration’ for the purpose of any contractual
										relationship.
									</li>
									<li> Participation in the program is strictly through registration and completion of KYC process by the
										influencers and HAFELE. Upon getting successful registration, the influencers will become the
										member under the program. The Program entitles its registered members the right to earn
										loyalty points on scanning the QR code, which can be redeemed by the way of bank account
										transfer or redemption through reward catalogue, which shall be governed by the redemption
										policy notified by HAFELE from time to time for the Program.
									</li>
									<li>  The redemption policy and other conditions notified in due course (of the Program) will always be
										read along and would be applicable with the Terms and Conditions of the Program.
									</li>
									<li>
										This program is applicable to all over India but is subject to Tamil Nadu Prize Schemes
										(Prohibition) Act-1979 and the following points are applicable exclusively for Influencers in the
										state of Tamil Nadu:
										<ul class="underlist">
											<li>a) Any benefits or scheme under this program if determined by drawing of any ticket, lot,
												number or figure etc. shall not be applicable in the State of Tamil Nadu.
											</li>
											<li>b) Any benefits or scheme under this program which are not in compliance with the Tamil Nadu
												Prize Schemes (Prohibition) Act-1979 shall not be applicable for the influencers having
												residence in the State of Tamil Nadu.
											</li>
											<li>c) In the State of Tamil Nadu, the Pre-determined value of base rewards points provided for
												each product will be credited to members’ accounts upon successful scan of QR code on each
												purchase of HAFELE products.
											</li>
											<li>d) Only base point scheme is applicable for the state of Tamil Nadu where same reward points
												will be credited to the account of all the influencers upon every scanning of QR code and no
												luck or lot or number or figure involved in such base point scheme. Further the other offers
												such as geography booster, tier scheme, enrollment bonus etc. are not applicable for Tamil
												Nadu under this program.
											</li>
											<li>e) In the state of Tamil Nadu, different products carry different rewards points but a single
												product of the same category will always carry same number of rewards points on each scan
												against each purchase.
											</li>
										</ul>
									</li>
									<li>
										HAFELE reserves the right to reject any application for enrolment as its member at its sole
										discretion, without assigning any reason.
									</li>
									<li>Even after registration, HAFELE is well within its right to discontinue/ cancel the registration of a
										registered member i.e. Influencer(s) without assigning any reasons, if it is found at any time that
										the information provided by the applicant is incomplete, incorrect, false and/ or fake etc.
									</li>
									<li> HAFELE will not be responsible for any damaged/ mutilated/ lost-in-transit/ incorrectly-filled
										forms. Member will be sole responsible for the information filled/ provided in the registration
										form.
									</li>
									<li>HAFELE shall neither be responsible for any fake or fictitious entry/ registration submitted by the
										Influencer under this Program nor shall HAFELE be liable to check the authenticity or credentials
										of the Influencers. Any issue relating to impersonation or inappropriate usage will not be
										investigated by HAFELE and/ or through its professional agency, however HAFELE would have the
										right to suspend or terminate any registration in case of receipt of any compliant in this regard.
									</li>
									<li>The registration in the program would complete only when all documents mentioned in the
										registration form are completed in all aspects. Any defect or unavailability of the documents
										under the Program can disentitle or cause suspension or deregistration and Cancellation for any
										Influencer, as determined by HAFELE.
									</li>
									<li> Privacy of the data and usage will be maintained, and HAFELE will take its best endeavor so far as
										technically and feasibly possible and reasonable to protect the privacy of the member’s database.
										For data protection you can visit our website  i.e. https:www.hafeleindia.com/en/info/dataprotection/646/.
									</li>

									<li> HAFELE Loyalty Points is the name for the total credit points that you may accumulate based on
										your purchases of HAFELE products as a HAFELE Influencer and it will be subject to the method/
										scheme stipulated by HAFELE at its sole discretion from time to time.
									</li>
									<li> HAFELE Loyalty Points may not be liquidated or redeemed in any manner except the manner as
										set out here under the program.
									</li>
									<li> It is expressly stated that HAFELE Loyalty Points is neither a negotiable instrument nor a
										consideration and nothing contained herein or elsewhere shall entitle you to any claim or action
										against HAFELE INDIA PRIVATE LIMITED or any of its affiliates, subsidiaries, directors, officers or
										employees regarding the valuation, redemption or liquidation of any HAFELE Loyalty Points.
									</li>
									<li>If some Loyalty points and/or rewards / benefits are wrongly credited into any Influencer’s
										account, the same can be debited or reversed by HAFELE by itself and the concerned Influencer
										shall be informed accordingly and no request will be entertained in this regard.
									</li>
									<li> If a member is facing any difficulty or error while scanning the QR code or entering the code
										manually, he can directly reach out to program helpdesk at *number* and report the code to the
										agent/ representative/ manager.
									</li>
									<li> In case of a used QR code is scanned again through the app, the system will give an error
										message and member has to scan the QR code of a fresh purchased from HAFELE.
									</li>
									<li> In case of any controversy on the points and/ or rewards/ benefits, the same shall be looked into/
										resolved only upon production of the necessary supporting transaction bill/ invoice / MRP tag to
										HAFELE's Call Centre. The decision taken by HAFELE will be final in this regard. No request will be
										entertained in this regard.
									</li>
									<li> Point conversion percentage can change anytime without prior intimation by HAFELE.
									</li>
									<li>
										You may accumulate HAFELE Loyalty Points in the following manner –
										<ul class="underlist">
											<li>
												− By scanning the QR codes
											</li>
											<li>
												− Onboarding Bonus
											</li>
											<li>
												− Tier Bonus (if applicable)
											</li>
											<li>
												− Multiplier bonus
											</li>
											<li>
												− Geography bonus
											</li>
											<li>
												− Engagement Bonus (if applicable)
											</li>
											<li>
												- Sales driving Bonus (if applicable)
											</li>
										</ul>
									</li>
									<li>You may redeem HAFELE Loyalty Points as per the redemption catalogue that will be intimated to
										you and you can also convert it into e-cash (Account transfer). Please note that HAFELE Loyalty
										Points shall not be redeemable in any other way except as specifically mentioned herein.
									</li>
									<li> HAFELE Loyalty Points are non-transferable and may not be assigned, traded or otherwise
										transferred to anyone else.
									</li>
									<li>HAFELE reserves the right to withdraw any or all promotional benefits, at any point of time
										without prior intimation to members/ influencers.
									</li>
									<li> Welcome bonus (enrollment bonus) may be given to the members once they get successfully
										enrolled into the program by completing the KYC through Mobile App. This welcome bonus may
										be a one-time bonus and will not be repeated.
									</li>
									<li> Welcome Bonus points will be redeemed once the influencer/member reach the minimum
										threshold require for redemption as defined by Hafele.
									</li>
									<li>
										HAFELE INDIA PRIVATE LIMITED reserves the right to change form and manner in which HAFELE
										Loyalty Points may be earned or redeemed at any time during the program. It is clarified that,
										without limiting the generality of the foregoing, HAFELE INDIA PRIVATE LIMITED may
										<ul class="underlist">
											<li>
												a. Prescribe a fresh scheme and promotional offers wherein the chances of earning HAFELE
												Loyalty Points may be increased or decreased for a particular period of time.
											</li>
											<li>
												b. Change or replace or discontinue the gifts available for HAFELE loyalty points
												redemption.
											</li>
										</ul>
									</li>
									<li> Any fraud or abuse of the rewards point/benefits arising out of the Program is subject to
										appropriate administrative and/ or legal action by HAFELE, including forfeiture of accumulated
										points and related rewards/ benefits, and may result into the suspension or cancellation of the
										membership.
									</li>
									<li>  HAFELE cannot be held responsible in any manner if the services provided by the Influencer, does
										not provide appropriate or satisfactory services and HAFELE holds out no warranty and makes no
										representation in respect of the Influencer’ s services enrolled in the Program.
									</li>
									<li>  Any product purchased by any Influencer would be subject to standard product warranty terms
										(if any).
									</li>
									<li>  HAFELE shall not, in any way, be liable or under any legal obligation to the Influencers/ members
										if the Program is withdrawn or modified/ cancelled due to statutory enactments, judicial / quasijudicial/ tribunal/ forum orders or any other force majeure reasons beyond its control.
									</li>
									<li>  HAFELE will not accept any responsibility for circumstances or situations that are beyond its
										reasonable reach or control, and that may cause a delay or inability to fulfill requests of the
										registered Influencer under normal circumstances.
									</li>
									<li>  Any dispute, claim or legal action against HAFELE and any of its directors, official, agencies and
										their respective employees as a result of this activity/ Program shall be subject to Mumbai
										jurisdiction exclusively.
									</li>
									<li>  These terms may be modified, altered, deleted or replaced, by addition, deletion or substitution,
										in whole or in part, by HAFELE INDIA PRIVATE LIMITED from time to time or terminate the
										program at any time without any prior intimation the influencers/ members.
									</li>
									<li>  You expressly agree and consent that participation in the program is at your sole risk and
										consequences.
									</li>
									<li>  HAFELE assumes and accepts no liability or responsibility for any (i) errors, omissions or in
										accuracies of the program or any information to this program, (ii) personal injury or property
										damage of any nature resulting from your access to and/or participation in the program, (iii) any
										unauthorized access to or use of HAFELE’s secure servers and/or any and all personal information
										and/or financial information that may be contained therein, (iv) any deletion of data or
										interruption or discontinuance of transmission to or from or through the program.
									</li>
									<li>  HAFELE may make available certain third-party goods and services through this program. HAFELE
										makes no representation or warranty regarding such goods and services and you are strongly
										advised to take such prudent measures while relying on said goods or services as you would if you
										obtained the goods or services of your own accord independent of this program. HAFELE shall not
										be responsible in any manner for warranty, any harm or damages arising from your use, access or
										lack thereof to any such goods or services make available from third party.
									</li>
									<li>  Limitation of Liability: In no event shall HAFELE be liable to you or any third party for any direct,
										indirect, consequential, exemplary, incidental, special or punitive damages, including damages
										arising from your participation in the program in any manner., You specifically agree and
										understand that HAFELE is not liable to you for any content or illegal conduct of any third party
										and that you alone accept such risk of harm.
									</li>
									<li> Indemnification: You agree to defend, indemnify and hold harmless HAFELE from and against any
										and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but
										not limited to attorney’s fees) arising from (i) your use of a participation in the program, (ii) your
										violation of any terms of these terms and (iii) your negligence, fraud and wilful misconduct. This
										defense and indemnification obligations will survive these terms.
									</li>
									<li>  Any provision of these terms and conditions that is declared invalid or unenforceable by a court
										of competent jurisdiction shall be indicative to the extent of such invalidity or unenforceability
										without invalidating or rendering unenforceable the remaining provisions hereof.
									</li>
									<li>  You will not use your earning rewards points in a way that is abusive or offensive or for an illegal,
										immoral or fraudulent purpose which is against the public policy.
									</li>
									<li>  Any failure of the members to comply with any of these terms and conditions or any violation,
										fraud or abuse of the program may result in forfeiture of all HAFELE loyalty points and may lead
										to termination without compensation.
									</li>
									<li>  All products shown in the catalogue are indicative and for representation purpose only.</li>
									<li>  Rewards redemption is strictly as per the process laid down by HAFELE.</li>
									<li>  The Loyalty Points mentioned against the rewards and values of the Products are subject to
										change at any time without prior notice. Redemptions against chosen product is subject to
										availability of that product at the time of generation of the redemption request.
									</li>
									<li>  All products featured in the catalogue are subject to availability of the goods and supplier
										warranties/ restrictions, at the time of redemption. HAFELE gives no warranty whatsoever with
										respect to the products/ services offered in the rewards program.
									</li>
									<li>  HAFELE reserves the right to cancel, change or substitute any reward at any time without any
										prior notice. HAFELE or its agency will not be responsible for products or vouchers are stolen, lost
										or damaged in transit.
									</li>
									<li>  Product received in damaged condition should be reported within 48 hours. HAFELE or agency
										will not be responsible in any manner for the same beyond the 48 hours.
									</li>
									<li>  HAFELE takes no responsibility for change or non-availability of products at the time of actual
										redemption.
									</li>
									<li>  The merchandise available in the catalogue would carry its routine manufacturer warranty unless
										mentioned otherwise thereof. For all requests for maintenance/ warranty/ replacement issues,
										the Influencer have to directly contact respective the manufacturer/authorized agent/distributor
										of the merchandise.
									</li>
									<li>  Upon becoming member under this program, there is no joint venture, partnership, employment
										or agency relationship created between member/ influencers and HAFELE.
									</li>
									<li>  Influencer/ member/ you give your consent that upon becoming successful member under this
										program through electronic mode, HAFELE will communicate about the program related
										information only through electronic mode such as SMS, email, Whatsapp or any other
										appropriate mode of electronic communication.
									</li>
									<li>  Influencer/ member/ you give your consent that you are not minor and/ or below the age of
										majority i.e. 18 years and further you have attained the age majority of 18 years under the
										Majority Act, 1875 on the date of registration under this program.
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  );
}
