import React from 'react'
import Footer from '../common/Footer'
import HomeHeader from './HomeHeader'

export default function HomeContact() {
    return (
        <>
            <div className="wrapper home-2">
                <HomeHeader />
                <div id="divContact">
                    <section className="blog-area pt70 min570">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="all-single-blog">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="heading-banner-title">
                                                    <h4 className="text-center cntcth4">Contact Us</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-md-4 col-xs-12">
                                                <figure className="infodetail">
                                                    <i className="fa fa-map-marker"></i>
                                                    <figcaption>
                                                        <h4>Program  Center</h4>
                                                        <address>
                                                            Hafele India Pvt Ltd
                                                            <br />
                                                            Office No 3, Building "A" BETA,
                                                            I - Think Techno Campus,
                                                            Opp Kanjurmarg Station East,
                                                            Mumbai - 400042
                                                            India
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <figure className="infodetail">
                                                    <i className="fa fa-phone"></i>
                                                    <figcaption>
                                                        <h4>Timing</h4>
                                                        <address>
                                                            09:30AM to 5:30PM (Mon-Sat)<br />
                                                            9289422000
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <figure className="infodetail">
                                                    <i className="fa fa-envelope-o"></i>
                                                    <figcaption>
                                                        <h4 className="mail">Email</h4>
                                                        <address>
                                                            info@hafelecup.in
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}
