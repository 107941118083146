import React, { useState, useEffect, useCallback } from "react";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SalesHeader from "./common/SalesHeader";
import AMHeader from "./common/AMHeader";
import RMHeader from "./common/RMHeader";
import PRDHeader from "./common/PRDHeader";
import Barchart from "./charts/Barchart";
import DonutChart from "./charts/DonutChart";
import Influencer from "./charts/Influencer";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import Oval from "./loader/CircleLoade";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import ResponsivePagination from "react-responsive-pagination";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import _ from "lodash";

export default function Dashboard() {
  const [isVisible, setIsVisible] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [productData, setProductData] = useState([]);
  const [points, setPoints] = useState([]);
  const [articles, setArticles] = useState([]);
  const [productQrStatus, setProductQrStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [articleItems, setArticleItems] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [articleoptions, setArticleOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [articleErr, setArticleErr] = useState(false);
  const [searchSapData, setSearchSapData] = useState("");

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState("10");
  let token = localStorage.getItem("token");

  const handleScroll = () => {
    // const scrollTop = window.pageYOffset;
    // if (scrollTop > 200) {
    //     setIsVisible(true);
    // } else {
    //     setIsVisible(false);
    // }
  };
  const scrollToTop = () => {
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    // });
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   let token = localStorage.getItem("token");
  //   if (token) {
  //     try {
  //       setLoading(true);
  //       axios
  //         .get(Constants.baseUrl + `program/dashboard/sales`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Accept-Language": localStorage.getItem("langauge"),
  //             platform: Constants.pt,
  //             appVersion: Constants.av,
  //             program: Constants.program,
  //           },
  //         })
  //         .then((res) => {
  //           if (res.status === 201) {
  //             if (res.data.code === 10001) {
  //               setLoading(false);
  //               setDashboard(res.data.output);
  //             }
  //           } else {
  //             // swal(res.data.msg);
  //           }
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //   }

  //   if (token) {
  //     try {
  //       setLoading(true);
  //       axios
  //         .post(
  //           Constants.baseUrl + `capture/qr/product_report_trend`,
  //           {},
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Accept-Language": localStorage.getItem("langauge"),
  //               appVersion: Constants.av,
  //               platform: Constants.pt,
  //               program: Constants.program,
  //               company: Constants.company,
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           if (res.status === 201) {
  //             if (res.data.code === 10001) {
  //               setLoading(false);
  //               setProductData(res.data.output);
  //             }
  //           } else {
  //             // swal(res.data.msg);
  //           }
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //   }

  //   if (token) {
  //     try {
  //       setLoading(true);
  //       axios
  //         .get(Constants.baseUrl + `user/points_report`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Accept-Language": localStorage.getItem("langauge"),
  //             // appVersion: Constants.av,
  //             // platform: Constants.pt,
  //             // program: Constants.program,
  //             // company: Constants.company
  //           },
  //         })
  //         .then((res) => {
  //           if (res.status === 200) {
  //             if (res.data.code === 10001) {
  //               setLoading(false);
  //               setPoints(res.data.output);
  //               // setTotalScan(toString(res.data.output.total_Scan))
  //             }
  //           } else {
  //             // swal(res.data.msg);
  //           }
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //   }

  //   if (token) {
  //     try {
  //       setLoading(true);
  //       axios
  //         .get(
  //           Constants.baseUrl +
  //             `program/child/product?sortBy=name&sortAsc=true&limit=10000&page=1`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Accept-Language": localStorage.getItem("langauge"),
  //               appVersion: Constants.av,
  //               platform: Constants.pt,
  //               program: Constants.program,
  //               company: Constants.company,
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           if (res.data.code === 10001) {
  //             setLoading(false);
  //             setArticles(res.data.output.results);
  //           } else {
  //             // swal(res.data.msg);
  //           }
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //   }
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [apiCallsMade, setApiCallsMade] = useState(false);

  useEffect(() => {
    if (!apiCallsMade) {
      let token = localStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          // Make all your API calls here
          Promise.all([
            axios.get(Constants.baseUrl + `program/dashboard/sales`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                platform: Constants.pt,
                appVersion: Constants.av,
                program: Constants.program,
              },
            }),
            axios.post(
              Constants.baseUrl + `capture/qr/product_report_trend`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Accept-Language": localStorage.getItem("langauge"),
                  appVersion: Constants.av,
                  platform: Constants.pt,
                  program: Constants.program,
                  company: Constants.company,
                },
              }
            ),
            axios.get(Constants.baseUrl + `user/points_report`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
              },
            }),
            // axios.get(
            //   Constants.baseUrl +
            //     `program/child/product?sortBy=name&sortAsc=true&limit=10000&page=1`,
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       "Accept-Language": localStorage.getItem("langauge"),
            //       appVersion: Constants.av,
            //       platform: Constants.pt,
            //       program: Constants.program,
            //       company: Constants.company,
            //     },
            //   }
            // ),
            // axios.get(
            //   Constants.baseUrl +
            //     `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
            //       "user_id"
            //     )}&sortBy=createdDateTime&sortAsc=false&limit=10&page=1`,
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       "Accept-Language": localStorage.getItem("langauge"),
            //       appVersion: Constants.av,
            //       platform: Constants.pt,
            //       program: Constants.program,
            //       company: Constants.company,
            //     },
            //   }
            // ),
          ]).then(
            ([
              salesRes,
              productTrendRes,
              pointsRes,
              //articlesRes,
              //productQrStatusRes,
            ]) => {
              setLoading(false);
              if (
                salesRes.status === 201 &&
                productTrendRes.status === 201 &&
                pointsRes.status === 200
              ) {
                setDashboard(salesRes.data.output);
                setProductData(productTrendRes.data.output);
                setPoints(pointsRes.data.output);
                //setArticles(articlesRes.data.output.results);
                //setProductQrStatus(productQrStatusRes.data.output.results);
                setApiCallsMade(true); // Update state to indicate API calls have been made
              }
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    }

    getProductQrStatusData(1);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [apiCallsMade]);

  const getProductQrStatusData = async (pageNum) => {
    setLoading(false);
    await axios
      .get(
        Constants.baseUrl +
          `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
            "user_id"
          )}&sortBy=createdDateTime&sortAsc=false&&limit=${limit}&page=${pageNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            program: Constants.program,
            company: Constants.company,
          },
        }
      )
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setProductQrStatus(dt?.output?.results);
          setTotalPages(dt?.output?.totalPages);
        }
        if (dt.result === "error" || dt.result === "dialog") {
          setProductQrStatus(null);
          setTotalPages(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    Plant: Yup.string().required("Plant is required"),
    //Article: Yup.string().required("Article is required"),
    Codes: Yup.number()
      .required("No of Codes is required")
      .positive()
      .integer(),
  });

  const handlePrintCoupons = () => {
    let token = localStorage.getItem("token");
    if (token) {
      if (
        localStorage.getItem("requestId") > 0 &&
        localStorage.getItem("programProductId") > 0 &&
        localStorage.getItem("sapCode") != ""
      ) {
        try {
          setLoading(true);
          axios
            .post(
              Constants.baseUrl + `program/child/download/programProductQr`,
              {
                requestId: localStorage.getItem("requestId"),
                programProductId: localStorage.getItem("programProductId"),
                sapCode: localStorage.getItem("sapCode"),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Accept-Language": localStorage.getItem("langauge"),
                  appVersion: Constants.av,
                  platform: Constants.pt,
                  program: Constants.program,
                  company: Constants.company,
                },
                responseType: "blob",
              }
            )
            .then((res) => {
              if (res.status === 201) {
                if (res.data) {
                  const fileData = new Blob([res.data], { type: "text/pdf" });
                  saveAs(fileData, "programProductQR.pdf");
                  axios
                    .get(
                      Constants.baseUrl +
                        `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
                          "user_id"
                        )}&sortBy=createdDateTime&sortAsc=false&limit=10&page=1`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                          "Accept-Language": localStorage.getItem("langauge"),
                          appVersion: Constants.av,
                          platform: Constants.pt,
                          program: Constants.program,
                          company: Constants.company,
                        },
                      }
                    )
                    .then((res) => {
                      setProductQrStatus(res.data.output.results);
                    });
                  // swal({
                  //   text: res.data.msg,
                  //   timer: 2000,
                  //   buttons: false,
                  // });

                  localStorage.removeItem("requestId");
                  localStorage.removeItem("programProductId");
                  localStorage.removeItem("sapCode");
                } else if (res.data.code === 16001) {
                  swal({
                    text: res.data.msg,
                    timer: 2000,
                    buttons: false,
                  });
                } else {
                  swal({
                    text: res.data.msg,
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                }
                setLoading(false);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        swal({
          text: "Please generate coupon and then print.",
          icon: "error",
          timer: 3000,
          buttons: false,
        });
      }
    }
  };

  const handlePrintCouponsOnlist = (requestId, programProductId, sapCode) => {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        setLoading(true);
        axios
          .post(
            Constants.baseUrl + `program/child/downloadPdf/programProductQr`,
            {
              requestId: requestId,
              programProductId: programProductId,
              sapCode: sapCode,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                program: Constants.program,
                company: Constants.company,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            if (res.status === 200) {
              if (res.data) {
                const fileData = new Blob([res.data], { type: "text/pdf" });
                saveAs(fileData, "programProductQR.pdf");
                axios
                  .get(
                    Constants.baseUrl +
                      `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
                        "user_id"
                      )}&sortBy=createdDateTime&sortAsc=false&limit=10&page=1`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                        company: Constants.company,
                      },
                    }
                  )
                  .then((res) => {
                    setProductQrStatus(res.data.output.results);
                  });
              } else if (res.data.code === 16001) {
                swal({
                  text: res.data.msg,
                  timer: 2000,
                  buttons: false,
                });
              } else {
                swal({
                  text: res.data.msg,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              }
              setLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePrintCouponsOnlistData = (
    requestId,
    programProductId,
    sapCode
  ) => {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        setLoading(true);
        axios
          .post(
            Constants.baseUrl + `program/child/downloadPdf/programProductQr`,
            {
              requestId: requestId,
              programProductId: programProductId,
              sapCode: sapCode,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                program: Constants.program,
                company: Constants.company,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            if (res.status === 200) {
              if (res.data) {
                // const fileData = new Blob([res.data], { type: "text/pdf" });
                // saveAs(fileData, "programProductQR.pdf");

                // Create a URL for the PDF blob
                const pdfUrl = URL.createObjectURL(
                  new Blob([res.data], { type: "application/pdf" })
                );

                // Create an iframe to load the PDF and trigger the print dialog
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.src = pdfUrl;
                document.body.appendChild(iframe);

                iframe.onload = () => {
                  iframe.contentWindow.print();
                };

                // axios
                //   .get(
                //     Constants.baseUrl +
                //       `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
                //         "user_id"
                //       )}&sortBy=createdDateTime&sortAsc=false&limit=10&page=1`,
                //     {
                //       headers: {
                //         Authorization: `Bearer ${token}`,
                //         "Accept-Language": localStorage.getItem("langauge"),
                //         appVersion: Constants.av,
                //         platform: Constants.pt,
                //         program: Constants.program,
                //         company: Constants.company,
                //       },
                //     }
                //   )
                //   .then((res) => {
                //     setProductQrStatus(res.data.output.results);
                //   });
              } else if (res.data.code === 16001) {
                swal({
                  text: res.data.msg,
                  timer: 2000,
                  buttons: false,
                });
              } else {
                swal({
                  text: res.data.msg,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              }
              setLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  let headerComponent;

  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (
    localStorage.getItem("user_type") === "RO" ||
    localStorage.getItem("user_type") === "HO" || localStorage.getItem("user_type") === "ADMIN"
  ) {
    headerComponent = <RMHeader />;
  } else if (localStorage.getItem("user_type") === "PRD") {
    headerComponent = <PRDHeader />;
  }

  const searchSapDataApi = async (query) => {
    if (query.length >= 2) {
      const searchData = `&name=${query}`;
      setLoading(true);
      try {
        const res = await axios.get(
          `${Constants.baseUrl}program/child/product?sortBy=name&sortAsc=true&limit=50&page=1${searchData}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              program: Constants.program,
              company: Constants.company,
            },
          }
        );
        setLoading(false);
        if (res.data.code === 10001) {
          const updatedItems = res.data.output.results.map((item) => ({
            id: `${item.id}_${item.sapCode}`, // Adjusted to match your API response
            name: item.sapCode,
          }));
          console.log("API Response:", updatedItems);
          setArticleItems(updatedItems);
        } else {
          console.error(res.data.msg);
          setArticleItems([]);
          setSelectedArticle("");
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setArticleItems([]);
        setSelectedArticle("");
      }
    } else {
      setArticleItems([]);
      setSelectedArticle("");
    }
  };

  const debounceSearch = useCallback(
    _.debounce((query) => {
      searchSapDataApi(query);
    }, 500),
    []
  );

  const handleOnSearch = (string) => {
    setSearchSapData(string);
    debounceSearch(string);
  };

  const handleOnKeyUp = (event) => {
    if (event.target.value.length >= 2) {
      handleOnSearch(event.target.value);
    }
  };

  const handleOnClick = (event) => {
    if (event.target.value.length >= 2) {
      handleOnSearch(event.target.value);
    }
  };

  const handleOnHover = (result) => {
    // Handle hover if needed
  };

  const handleOnSelect = (item) => {
    setSelectedArticle(item.id);
    // Handle selection if needed
  };

  const formatResult = (item) => {
    console.log("Formatted Item:", item);
    return (
      <div className="result-item">
        <span>{item.name}</span>
      </div>
    );
  };

  const formatToIndianNumber = (num) => {
    // Convert string to number if needed
    const number = Number(num);
    
    // Check if num is a valid number
    if (isNaN(number)) return num;
    
    const [integerPart, decimalPart] = number.toFixed(2).split('.');
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    return `${otherDigits ? formattedOtherDigits + ',' : ''}${lastThreeDigits}.${decimalPart}`;
  };

  return (
    <>
      {loading ? <Oval /> : null}
      {headerComponent}
      <section className="blog-area pt182">
        <div className="container-fluid">
          <div className="row">
            {localStorage.getItem("user_type") === "PRD" ? (
              <>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="all-single-blog">
                    <div className="well">
                      <Formik
                        initialValues={{
                          Plant: "",
                          //Article: "",
                          Codes: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          // Handle form submission with API call
                          // Replace this with your API call logic

                          let token = localStorage.getItem("token");
                          if (token) {
                            // const [programProductId, sapCode] =
                            //   values.Article.split("_");

                            const [programProductId, sapCode] =
                              selectedArticle.split("_");
                            if (programProductId > 0 && sapCode != "") {
                              setArticleErr(false);
                              try {
                                setLoading(true);
                                axios
                                  .post(
                                    Constants.baseUrl +
                                      `program/child/programProductQr/generate`,
                                    {
                                      plant: values.Plant,
                                      programProductId: programProductId,
                                      sapCode: sapCode,
                                      numberOfProgramProductQRCodes:
                                        values.Codes,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Accept-Language":
                                          localStorage.getItem("langauge"),
                                        appVersion: Constants.av,
                                        platform: Constants.pt,
                                        program: Constants.program,
                                        company: Constants.company,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    if (res.status === 201) {
                                      if (res.data.code === 10001) {
                                        localStorage.setItem(
                                          "requestId",
                                          res.data.output.requestId
                                        );
                                        localStorage.setItem(
                                          "programProductId",
                                          res.data.output.programProductId
                                        );
                                        localStorage.setItem(
                                          "sapCode",
                                          res.data.output.sapCode
                                        );

                                        swal({
                                          text: res.data.msg,
                                          timer: 2000,
                                          buttons: false,
                                        });

                                        resetForm();
                                        setSelectedArticle("");
                                        setArticleItems([]);

                                        axios
                                          .get(
                                            Constants.baseUrl +
                                              `program/child/productQrStatusByUserId?userId=${localStorage.getItem(
                                                "user_id"
                                              )}&sortBy=createdDateTime&sortAsc=false&limit=10&page=1`,
                                            {
                                              headers: {
                                                Authorization: `Bearer ${token}`,
                                                "Accept-Language":
                                                  localStorage.getItem(
                                                    "langauge"
                                                  ),
                                                appVersion: Constants.av,
                                                platform: Constants.pt,
                                                program: Constants.program,
                                                company: Constants.company,
                                              },
                                            }
                                          )
                                          .then((res) => {
                                            setProductQrStatus(
                                              res.data.output.results
                                            );
                                          });
                                      } else if (res.data.code === 16001) {
                                        swal({
                                          text: res.data.msg,
                                          timer: 2000,
                                          buttons: false,
                                        });
                                      } else {
                                        swal({
                                          text: res.data.msg,
                                          icon: "error",
                                          timer: 3000,
                                          buttons: false,
                                        });
                                      }
                                      setLoading(false);
                                    }
                                  });
                              } catch (error) {
                                console.log(error);
                              }
                            } else {
                              setArticleErr(true);
                            }
                          }
                        }}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            <div className="row">
                              {/* Plant Field */}
                              <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="form-group">
                                  <label>Plant</label>
                                  <Field
                                    as="select"
                                    className="form-control4"
                                    id="Plant"
                                    name="Plant"
                                  >
                                    <option value="">Select</option>
                                    <option value="IN60 - Bhiwandi">
                                      IN60 - Bhiwandi
                                    </option>
                                    <option value="IN20 - Bangalore">
                                      IN20 - Bangalore
                                    </option>
                                    <option value="IN30 - Delhi">
                                      IN30 - Delhi
                                    </option>
                                    <option value="IN40 - Kolkata">
                                      IN40 - Kolkata
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="Plant"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              {/* Article Field */}
                              <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="form-group">
                                  <label>Select Article No.</label>
                                  
                                  <ReactSearchAutocomplete
                                    items={articleItems}
                                    onSearch={handleOnSearch}
                                    onHover={handleOnHover}
                                    onSelect={handleOnSelect}
                                    formatResult={formatResult}
                                    onKeyUp={handleOnKeyUp} // Handle key up event
                                    onClick={handleOnClick} // Handle click event
                                    autoFocus
                                    placeholder="Search for SAP Code"
                                    styling={{
                                      border: "1px solid #ccc",
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                      hoverBackgroundColor: "#f0f0f0",
                                      color: "black",
                                      fontSize: "16px",
                                      iconColor: "black",
                                      lineColor: "#ccc",
                                      placeholderColor: "grey",
                                      clearIconMargin: "3px 8px 0 0",
                                      borderRadius: "0px",
                                      minHeight: "31px",
                                      zIndex: 1000,
                                      overflowY: "auto",
                                    }}
                                    showIcon={true}
                                    showClear={true}
                                    maxResults={50}
                                  />
                                  {articleErr && (
                                    <div class="text-danger">
                                      Article is required
                                    </div>
                                  )}
                                  {/* <Field
                                    as="select"
                                    className="form-control2"
                                    id="Article"
                                    name="Article"
                                  >
                                    <option value="">Select</option>
                                    {articles.length > 0 &&
                                      articles.map((item, index) => {
                                        return (
                                          <>
                                            <option
                                              key={index}
                                              value={`${item.id}_${item.sapCode}`}
                                            >
                                              {item.sapCode}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </Field>
                                  <ErrorMessage
                                    name="Article"
                                    component="div"
                                    className="text-danger"
                                  /> */}
                                </div>
                              </div>
                              {/* Codes Field */}
                              <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="form-group">
                                  <label>No of Codes</label>
                                  <Field
                                    type="text"
                                    name="Codes"
                                    id="Codes"
                                    className="form-control4"
                                  />
                                  <ErrorMessage
                                    name="Codes"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Submit Buttons */}
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="form-group">
                                  <button
                                    type="submit"
                                    className="btn login_btn"
                                    // disabled={isSubmitting}
                                  >
                                    Generate Coupon
                                  </button>
                                  {/* <button
                                    type="button"
                                    className="btn login_btn"
                                    disabled={isSubmitting}
                                    onClick={handlePrintCoupons}
                                  >
                                    DOWNLOAD COUPONS
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h3>Product QR Status</h3>
                  <div className="table-responsive mbtable">
                    <table className="table table-border table-striped table-earning">
                      <tr>
                        <th>Request ID</th>
                        <th>Plant</th>
                        <th>Product Id</th>
                        <th>Article No.</th>
                        <th>Total No. Of Coupon</th>
                        <th>IP Address</th>
                        <th>User Id</th>
                        <th>Download Status</th>
                        <th>Action</th>
                      </tr>
                      {productQrStatus &&
                        productQrStatus.length > 0 &&
                        productQrStatus.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{item.requestId}</td>
                                <td>{item.plant}</td>
                                <td>{item.programProductId}</td>
                                <td>{item.sapCode}</td>
                                <td>{item.couponGeneratedCount}</td>
                                <td>{item.ipAddress}</td>
                                <td>{item.userId}</td>
                                <td>{item.qrDownloaded ? "YES" : "NO"}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn login_btn"
                                    onClick={() =>
                                      handlePrintCouponsOnlist(
                                        item.requestId,
                                        item.programProductId,
                                        item.sapCode
                                      )
                                    }
                                  >
                                    DOWNLOAD
                                  </button>
                                  <button
                                    type="button"
                                    className="btn login_btn"
                                    onClick={() =>
                                      handlePrintCouponsOnlistData(
                                        item.requestId,
                                        item.programProductId,
                                        item.sapCode
                                      )
                                    }
                                  >
                                    PRINT
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                    {totalPages > 1 && (
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          getProductQrStatusData(page);
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="all-single-blog">
                    {/* <div className="well2">
                      <div className="row">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>Region </label>
                            <select
                              className="form-control2"
                              id="Region"
                              name="Region"
                            >
                              <option value="0">Select</option>
                              <option value="3">EAST</option>
                              <option value="4">NORTH</option>
                              <option value="5">SOUTH</option>
                              <option value="6">WEST</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>Area Manager </label>
                            <select
                              className="form-control2"
                              id="Branch"
                              name="Branch"
                            >
                              <option value="0">Select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>Sales Executive </label>
                            <select
                              className="form-control2"
                              id="SalesExecutive"
                              name="SalesExecutive"
                            >
                              <option value="0">Select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>State </label>
                            <select
                              className="form-control2"
                              id="State"
                              name="State"
                            >
                              <option value="0">Select</option>
                              <option value="27">Andhra Pradesh</option>
                              <option value="1">Assam</option>
                              <option value="7">Bihar</option>
                              <option value="18">Chandigarh</option>
                              <option value="9">Chhattisgarh</option>
                              <option value="14">Delhi</option>
                              <option value="29">Goa</option>
                              <option value="30">Gujarat</option>
                              <option value="22">Haryana</option>
                              <option value="17">Himachal Pradesh</option>
                              <option value="16">Jammu Kashmir</option>
                              <option value="10">Jharkhand</option>
                              <option value="26">Karnataka</option>
                              <option value="23">Kerala</option>
                              <option value="20">Madhya Pradesh</option>
                              <option value="28">Maharashtra</option>
                              <option value="3">Manipur</option>
                              <option value="12">Meghalaya</option>
                              <option value="5">Mizoram</option>
                              <option value="4">Nagaland</option>
                              <option value="6">Odisha</option>
                              <option value="15">Punjab</option>
                              <option value="21">Rajasthan</option>
                              <option value="24">Tamil Nadu</option>
                              <option value="25">Telangana</option>
                              <option value="2">Tripura</option>
                              <option value="11">Uttar Pradesh</option>
                              <option value="19">Uttarakhand</option>
                              <option value="8">West Bengal</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>District </label>
                            <select className="form-control2">
                              <option value="0">Select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <label>City </label>
                            <select className="form-control2">
                              <option value="0">Select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                          <div className="form-group">
                            <input
                              id="btnsubmit"
                              className="btn login_btn"
                              type="submit"
                              value="Submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="heading-banner-title">
                          <h4>Performance summary Influencers</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col30 col-xs-12">
                        <div className="info-box_1 nf1 wd80">
                          <div
                            className="info-box-content"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <span className="info-box-text  bold text-center">
                              Influencers Performance
                            </span>
                            <span className="info-box-text">
                              Total Onboarded Influencers{" "}
                              <span className="bold">{dashboard?.members}</span>
                            </span>
                            <span className="info-box-number">
                              Total Approved Influencers{" "}
                              <span className="bold">
                                {dashboard?.memberApproved}
                              </span>
                            </span>
                            <span className="info-box-number">
                              Total Active Influencers{" "}
                              <span className="bold">
                                {dashboard?.memberActive}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col40 col-xs-12">
                        <div className="info-box_1 nf3 wd80">
                          <div
                            className="info-box-content"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <span className="info-box-text  bold text-center">
                              Product Reporting Trend
                            </span>
                            <table className="no-border table-borderless">
                              <tr>
                                <th>Product Category</th>
                                <th className="text-center">No. Of Scans</th>
                                <th className="text-center">
                                  Total Sales in INR
                                </th>
                                <th className="text-right">Points Awarded</th>
                              </tr>
                              {productData.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>{item.category}</td>
                                      <td className="text-center">
                                        {item.total_scan}
                                      </td>
                                      <td className="text-center">
                                        {formatToIndianNumber(item?.total_price)}
                                      </td>
                                      <td className="text-right">
                                        {item.point_award}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              {/* <tr>
															<td>Kitchen Fittings  </td>
															<td className="text-center">76</td>
															<td className="text-center" id="numbrrr1">418598.00</td>
															<td className="text-right" id="pnt1">2975.00</td>
														</tr>
														<tr>
															<td>Glass Fittings  </td>
															<td className="text-center">1</td>
															<td className="text-center" id="numbrrr2">13595.00</td>
															<td className="text-right" id="pnt2">125.00</td>
														</tr>
														<tr>
															<td>Sliding Fittings </td>
															<td className="text-center">4</td>
															<td className="text-center" id="numbrrr3">12592.00</td>
															<td className="text-right" id="pnt3">200.00</td>
														</tr> */}
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col30 col-xs-12">
                        <div className="info-box_1 nf2 wd80">
                          <div
                            className="info-box-content"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <span className="info-box-text  bold text-center">
                              Points Reporting Trend
                            </span>
                            <span className="info-box-text">
                              Total Scanned{" "}
                              <span id="pntscan" className="bold">
                                {dashboard?.scanTotal}
                              </span>
                            </span>
                            <span className="info-box-text">
                              Total Balance{" "}
                              <span id="pntblnc" className="bold">
                                {dashboard?.totalPointsBalance}
                              </span>
                            </span>
                            <span className="info-box-text">
                              Total Redeemed{" "}
                              <span id="pntredeem" className="bold">
                                {dashboard?.pointsRedeemed}
                              </span>
                            </span>
                            {/* {points.map((item, index) => {
                              return (
                                <>
                                  <span className="info-box-text">
                                    Total Scanned{" "}
                                    <span id="pntscan" className="bold">
                                      {item.total_Scan}
                                    </span>
                                  </span>
                                  <span className="info-box-text">
                                    Total Balance{" "}
                                    <span id="pntblnc" className="bold">
                                      {item.total_Price}
                                    </span>
                                  </span>
                                  <span className="info-box-text">
                                    Total Redeemed{" "}
                                    <span id="pntredeem" className="bold">
                                      {item.total_redeemed}
                                    </span>
                                  </span>
                                </>
                              );
                            })} */}
                          </div>
                        </div>
                      </div>
                      {/* <!-- 	<div className="col-md-3">
												<div className="info-box_1 nf3 wd80">
													<div className="info-box-content">
														<span className="info-box-text mt-35">Total redeemed points <span className="bold">40000.00</span></span>
														<span className="info-box-number">Total Balance points (Curr. liability) <span className="bold">2000.00</span></span>
													</div>

												</div>
											</div> --> */}
                    </div>
                    <div className="row">
                      <Influencer />

                      {/* <div className="col-md-4 col-xs-12">
											<p className='chart-heading'>Member On Board</p>
											<div id="chartdiv3" style={{ width: "100%", height: "250px" }}>
											</div>
										</div> */}
                      <DonutChart />
                      {/* <div className="col-md-4 col-xs-12">
											<div id="chartdiv2" style={{ width: "100%", height: "250px" }}>
											</div>
										</div> */}
                      <Barchart />
                      {/* <div className="col-md-4 col-xs-12">
											<p className='chart-heading'>Influencer</p>
											<div id="chartdiv1" style={{ width: "100%", height: "250px" }}>
											</div>
										</div> */}
                    </div>

                    <br />
                    <div className="row mt-15">
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_login">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Login Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_enrollment">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Enrollment Report{" "}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_product_wise">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Product Wise Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_redemption">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Redemption Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_otp_sms">
                            <div className="info-box-content">
                              <span className="info-box-text">OTP Report</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_qr_error">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                QR Error Scan Transaction Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_anomaly">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Anomaly Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/ticket_report">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Ticket Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="info-box_1 nflink wd80">
                          <Link to="/rpt_registered_users">
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Registered Users Report
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>

                      {localStorage.getItem("user_type") === "ADMIN" &&
                        <>
                        <div className="col-md-3">
                          <div className="info-box_1 nflink wd80">
                            <Link to="/rpt_warehouse">
                              <div className="info-box-content">
                                <span className="info-box-text">
                                  Warehouse Printing Data Report
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="info-box_1 nflink wd80">
                            <Link to="/rpt_sales_hierarchy">
                              <div className="info-box-content">
                                <span className="info-box-text">
                                  Sales Hierarchy Report
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="info-box_1 nflink wd80">
                            <Link to="/rpt_articles">
                              <div className="info-box-content">
                                <span className="info-box-text">
                                  Articles Report
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="info-box_1 nflink wd80">
                            <Link to="/rpt_sales_district_map">
                              <div className="info-box-content">
                                <span className="info-box-text">
                                Sales District Map Report
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                        </>
                      }

                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
      <Link
        id="scrollUp"
        to=""
        className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
        style={{ position: "fixed", zIndex: "2147483647" }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
    </>
  );
}
