import React from 'react'
import HomeHeader from './HomeHeader'
import Footer from '../common/Footer'

export default function HomeAboutHafele() {
    return (
        <>
            <div className="wrapper home-2">
                <HomeHeader />
                <div id="divAboutUs">
                    <section className="blog-area pt70 min570">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="all-single-blog">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="heading-banner-title">
                                                    <h4>About Hafele</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-md-12 col-xs-12 aboutv">
                                                <p>
                                                    Hafele India is a wholly-owned subsidiary of the Hafele Global network and has been operating in India since 2003 under the leadership of Mr. Jürgen Wolf (Managing Director). The ability of the company to understand the diverse Indian market has made it an authority in the field of architectural hardware, furniture and kitchen fittings and accessories. The company also has a strong presence in synergized product categories namely Home Appliances, Furniture Lighting, Sanitary and Surfaces catering to the focused demand from these industries.
                                                </p>
                                                <p>
                                                    The subsidiary has a strong nation-wide presence with offices in Mumbai, Pune, Ahmedabad, Bangalore, Chennai, Hyderabad, Delhi, Kolkata and Cochin. It has fullscale operations in Sri Lanka and Bangladesh with Regional offices and Design Showrooms in both the countries; and has also spread its operations to other regions of South Asia including Nepal, Bhutan and Maldives.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}
