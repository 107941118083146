import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SalesHeader from '../common/SalesHeader';
import AMHeader from '../common/AMHeader';
import RMHeader from '../common/RMHeader';
import axios from 'axios';
import * as Constants from "../../Constants/index"
import swal from 'sweetalert';
import { saveAs } from 'file-saver';
export default function RptAnomaly() {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const download = () => {
        let token = localStorage.getItem('token');
        try {
            axios.get(Constants.baseUrl + `reports/download/raiseTicketreport?userType=MEMBER`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {

                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'anomaly.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }







    let headerComponent;

    if (localStorage.getItem('user_type') === 'SALES') {
        headerComponent = <SalesHeader />;
    } else if (localStorage.getItem('user_type') === 'SH') {
        headerComponent = <AMHeader />;
    } else if (localStorage.getItem('user_type') === 'RO' || localStorage.getItem("user_type") === "HO") {
        headerComponent = <RMHeader />;
    }

    return (
        <>
            <div className="wrapper home-2">
                {headerComponent}
                <section className="blog-area pt182">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="all-single-blog rpt">
                                    <div className="row clearfix mb-15">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Name / ID</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label className="invisible" style={{ width: '100%' }}>hidden</label>
                                                <input id="btnsubmit" className="btn login_btn up-side" type="submit" value="Submit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="all-single-blog rpt">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="carrd1">
                                                <div className="card-header card-header-warning">
                                                    <h4 className="card-title">
                                                        Anomaly Report
                                                        <Link className="fa fa-file-excel-o pull-right" onClick={download} to="#"> Export</Link>
                                                    </h4>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <div className="table table-striped mb_01">
                                                        <table id="gridData" className="table table-bordered mytable" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        Sr.No.
                                                                    </th>
                                                                    <th scope="col">
                                                                        Member Name
                                                                    </th>
                                                                    <th scope="col">
                                                                        Member Mobile Number
                                                                    </th>
                                                                    <th scope="col">
                                                                        Region
                                                                    </th>
                                                                    <th scope="col">
                                                                        State
                                                                    </th>
                                                                    <th scope="col">
                                                                        District
                                                                    </th>
                                                                    <th scope="col">
                                                                        City
                                                                    </th>
                                                                    <th scope="col">
                                                                        Sales Executive Name
                                                                    </th>
                                                                    <th scope="col">
                                                                        Area Manager Name
                                                                    </th>
                                                                    <th scope="col">
                                                                        RSM Manager Name
                                                                    </th>
                                                                    <th scope="col">
                                                                        Anomaly Id
                                                                    </th>
                                                                    <th scope="col">
                                                                        Date Of Anomaly Scan
                                                                    </th>
                                                                    <th scope="col">
                                                                        Anomaly Status
                                                                    </th>
                                                                    <th scope="col">
                                                                        No. Of Anomaly Scans
                                                                    </th>
                                                                    <th scope="col">
                                                                        Anomaly Value Scanned
                                                                    </th>
                                                                    <th scope="col">
                                                                        Total Points Earned
                                                                    </th>
                                                                    <th scope="col">
                                                                        Total Points Redeemed
                                                                    </th>
                                                                    <th scope="col">
                                                                        Date Of First Scan
                                                                    </th>
                                                                    <th scope="col">
                                                                        Last Successful Scan ID
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            </div>
        </>
    )
}
