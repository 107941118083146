import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import axios from "axios";
import * as Constants from "../../Constants/index";

export default function Barchart() {
  useEffect(() => {
    const fetchData = async () => {
      let token = localStorage.getItem("token");
      if (token) {
        try {
          // Make the API request to fetch data
          const response = await axios.get(
            Constants.baseUrl + `user/member_on_month_wise`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                // appVersion: Constants.av,
                // platform: Constants.pt,
                // program: Constants.program,
                // company: Constants.company
              },
            }
          );

          // Extract the data from the API response
          const apiData = response.data.output;

          // Transform the API data into the format expected by amCharts
          const chartData = Object.keys(apiData).map((month) => ({
            month: month,
            member: parseInt(apiData[month]),
          }));

          am5.ready(function () {
            // Create root element
            var root = am5.Root.new("chartdiv3");

            // Disable amCharts logo
            root._logo.dispose();

            // Set themes
            root.setThemes([am5themes_Animated.new(root)]);

            // Create chart
            var chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
              })
            );

            // Add cursor
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            // Create axes
            var xRenderer = am5xy.AxisRendererX.new(root, {
              minGridDistance: 30,
            });
            xRenderer.labels.template.setAll({
              rotation: -50,
              centerY: am5.p50,
              centerX: am5.p100,
              paddingRight: 15,
            });

            xRenderer.grid.template.setAll({
              location: 1,
            });

            var xAxis = chart.xAxes.push(
              am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "month",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
              })
            );

            var yAxis = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {
                  strokeOpacity: 0.1,
                }),
              })
            );

            // Create series
            var series = chart.series.push(
              am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "member",
                sequencedInterpolation: true,
                categoryXField: "month",
                tooltip: am5.Tooltip.new(root, {
                  labelText: "{valueY}",
                }),
              })
            );

            series.columns.template.setAll({
              cornerRadiusTL: 0,
              cornerRadiusTR: 0,
              strokeOpacity: 0,
            });

            // Set data
            xAxis.data.setAll(chartData);
            series.data.setAll(chartData);

            // Set column colors
            series.columns.template.setAll({ fill: am5.color("#fa6464") }); // Set the desired color here

            // Make stuff animate on load
            series.appear(1000);
            chart.appear(1000, 100);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="col-md-4 col-xs-12">
        <p className="chart-heading">Member On Board</p>
        <div id="chartdiv3" style={{ width: "100%", height: "250px" }}></div>
      </div>
    </>
  );
}
