import React, { useEffect, useState } from "react";
import logo2 from "../assets/img/logo/logo2.png";
import logo_mobile from "../assets/img/logo/logo-mobile.png";
import combo from "../assets/img/combo.png";
import programstage from "../assets/img/programstage.png";
import Footer from "../component/common/Footer";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import Modal from "react-modal";

export default function LandingPage() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [landingOtpmodal, setLandingOtpmodal] = useState(false);
  const [landingotpmobile, setLandingotpmobile] = useState("");
  const [landingotp, setLandingotp] = useState("");
  const [landingotpmessage, setLandingotpmessage] = useState("");
	const [otperrors, setOtperrors] = useState({});

  // Enrollment form state
  const [name, setName] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [stateID, setStateID] = useState(0);
  const [district, setDistrict] = useState("");
  const [districtID, setDistrictID] = useState(0);
  const [sales, setSales] = useState("");
  const [salesID, setSalesID] = useState(0);
  const [stateFetch, setStateFetch] = useState([]);
  const [districtFetch, setDistrictFetch] = useState([]);
  const [salseDistrictData, setSalseDistrictData] = useState([]);
  const navigate = useNavigate();

	const stateData = async () => {
		try {
			await axios
				.get(Constants.baseUrl + `master/geography/state?limit=1000`, {
					headers: {
						"Accept-Language": localStorage.getItem("langauge"),
					},
				})
				.then((res) => {
					if (res.status === 201) {
						setStateFetch(res.data.output.results);
					} else {
						swal(res.data.msg);
					}
				});
		} catch (error) {
			console.log(error);
		}
	};

  useEffect(() => {
    
    stateData();

    const userId = localStorage.getItem("user_id");
    if (userId) {
      // swal('You are already logged in!');
      window.location = "/dashboard";
    }
    $(".navigator, login_show").on("click", function (e) {
      e.preventDefault();
      $(".popup_sidebar_sec").toggleClass("active");
    });
    $(".popup_sidebar_overlay, .widget_closer, .login_closer").on(
      "click",
      function () {
        $(".popup_sidebar_sec").removeClass("active");
      }
    );
  }, []);

  // call state api by state ID for get district name
  const handleStateChange = async (event) => {
    setSalseDistrictData([]);
    setSalesID("");
    setSales("");
    const selectedId = event.target.value;
    districtData(selectedId);
  };

  let token = localStorage.getItem("token");
  const districtData = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              // console.log(res)
              setState(res.data.output.name);
              setStateID(res.data.output.id);
              setDistrictFetch(res.data.output.districts);
            } else {
              swal(res.data.msg);
            }
          } else {
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const salesDistrictData = async (districtid) => {
    try {
      await axios
        .get(
          Constants.baseUrl +
            `auth/getSalesUserByDistrictId?id=${districtid}&userType=SALES&sortBy=name&sortAsc=true&limit=500&page=1`,
          {
            headers: {
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.data.code === 10001) {
            setSalseDistrictData(res.data.output.results);
          } else {
            setSalseDistrictData([]);
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const validateFormEnroll = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required";
    }
    if (!mobile2) {
      errors.mobile2 = "Mobile Number is required";
    } else if (!/^\d+$/.test(mobile2)) {
      errors.mobile2 = "Mobile Number must be numeric";
    } else if (mobile2.length !== 10) {
      errors.mobile2 = "Mobile Number must be 10 digits";
    }

    if (email != "" && !/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!state) {
      errors.state = "State is required";
    }
    if (!district) {
      errors.district = "District is required";
    }
    if (!sales) {
      errors.sales = "Salse is required";
    }

    return errors;
  };

  const register = async (event) => {
    event.preventDefault();

    setErrors({});

    const validationErrors = validateFormEnroll();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      const res = await axios
        .post(
          Constants.baseUrl + "auth/enroll",
          {
            name: name,
            email: email,
            mobile: mobile2,
            userType: "MEMBER",
            status: "DRAFT",
            parentId: salesID,
            parentName: sales,
            addressWork: {
              workAddress1: "",
              workAddress2: "",
              workAddress3: "",
              workCity: "",
              workState: state,
              workStateId: stateID,
              workdistrict: district,
              workdistrictId: districtID,
              workPincode: "",
              workCountry: "",
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
							setLandingotpmobile(res.data.output.mobile);
              setLandingotpmessage("User " + res.data.msg);
              setLandingotp('');
              setLandingOtpmodal(true);
							stateData();

              setName("");
              setMobile2("");
              setEmail("");
              setState("");
              setStateID(0);
              setDistrict("");
              setDistrictID(0);
              setSales("");
              setSalesID(0);

              // swal({
              //   text: 'User '+res.data.msg,
              //   buttons: false,
              //   timer: 2000,
              // });
            } else if (res.data.code === 12002) {
              swal({
                text: res.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            } else if (res.data.code === 16001) {
              swal({
                text: res.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const validateForm = () => {
    let errors = {};

    if (!mobile) {
      errors.mobile = "User ID is required";
    } 
    
    // else if (!/^\d+$/.test(mobile)) {
    //   errors.mobile = "User ID should be numeric";
    // } else if (mobile.length !== 10) {
    //   errors.mobile = "User ID should be 10 digits";
    // }

    if (!password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleLogin = async () => {
    setErrors({});

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }

    try {
      await axios
        .post(
          Constants.baseUrl + "auth/login-password",
          {
            mobile: mobile,
            password: password,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              localStorage.setItem("user_id", res.data.output.user.id);
              localStorage.setItem("user_type", res.data.output.user.userType);
              localStorage.setItem("user_name", res.data.output.user.name);
              localStorage.setItem("user_email", res.data.output.user.email);
              localStorage.setItem("user_mobile", res.data.output.user.mobile);
              localStorage.setItem("token", res.data.output.access.token);
              navigate("/dashboard");
            } else if (res.data.code === 16001) {
              swal({
                text: res.data.msg,
                buttons: false,
                onAfterClose: (window.location = "/"),
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

	const handleOtpKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const closeLandingOtpModal = () => {
    setLandingOtpmodal(false);
  };

	const validateLandingOtpForm = () => {
    let otperrors = {};

    if (!landingotp) {
      otperrors.otper = "OTP is required";
    } else if (!/^\d+$/.test(landingotp)) {
      otperrors.otper = "OTP should be numeric";
    } else if (landingotp.length !== 4) {
      otperrors.otper = "OTP should be 4 digits";
    }

    return otperrors;
  };

  const handleLandingOtp = async () => {
    setOtperrors({});

    const validationOtpErrors = validateLandingOtpForm();

    if (Object.keys(validationOtpErrors).length > 0) {
      setOtperrors(validationOtpErrors);
      return; // Don't proceed with the API call if there are validation errors
    }

    try {
      await axios
        .post(
          Constants.baseUrl + "auth/verify-otp/enroll",
          {
            mobile: landingotpmobile,
            otp: landingotp,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              swal({
                text: res.data.msg,
                buttons: false,
                timer: 2000,
              });

							setLandingOtpmodal(false);
            } else if (res.data.code === 16001) {
              swal({
                text: res.data.msg,
                buttons: false,
                timer: 2000,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <Modal
        isOpen={landingOtpmodal}
        onRequestClose={closeLandingOtpModal}
        contentLabel="OTP Verify"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "400px",
            height: "250px",
            margin: "auto",
          },
        }}
      >
        
        <div className="row" style={{ margin: "10px 10px 10px 10px" }}>
          <div className="col-md-12">
						<p>{landingotpmessage}</p>
            <div className="form-group">
              <label htmlFor="otp">Enter Otp</label>
              <input
                className="form-control frm"
                data-val="true"
                maxLength="4"
                placeholder="Enter Otp"
                type="text"
                value={landingotp}
                onChange={(event) => {
                  const input = event.target.value;
                  if (/^\d*$/.test(input)) {
                    setLandingotp(input);
                  }
                }}
                onKeyDown={handleOtpKeyDown}
              />
              {otperrors.otper && (
                <p className="login-error-message">{otperrors.otper}</p>
              )}
            </div>
            <div className="form-group mtop15">
              <input
                type="submit"
                className="btn btn-primary custombtn"
                id="btnSubmit"
                onClick={handleLandingOtp}
                value="Submit"
              />
            </div>
            <div className="form-group mtop15">
              <button
                className="btn btn-primary custombtn"
                onClick={closeLandingOtpModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="wrapper home-2">
        <header>
          <div class="header-container header-sticky sticky">
            <div className="header-top-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="header-top-menu text-left">
                      <ul>
                        <li className="">
                          <Link to="mailto:info@hafelecup.in">
                            <i className="fa fa-envelope-o"></i>{" "}
                            info@hafelecup.in
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-8  text-right">
                    <div className="header-top-menu">
                      <ul>
                        <li className="support">
                          <Link to="tel:+919289422000">
                            <i className="fa fa-phone"></i> +91 9289422000{" "}
                          </Link>
                        </li>
                        <li className="support hidden-xs">
                          <span>
                            {" "}
                            <i className="fa fa-clock-o"></i> 09:30 AM to 05:30
                            PM{" "}
                          </span>
                        </li>
                        {showForgotPassword === false ? (
                          <li className="account">
                            <Link to="#" className="navigator">
                              <i className="fa fa-sign-in"></i> LOG IN{" "}
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-middel-area hidden-xs">
              <div class="container">
                <div class="row">
                  <div class="col-md-2 col-sm-2 col-xs-6">
                    <div class="logo logo1">
                      <Link to="javascript:void();">
                        <img src={logo2} alt="" />
                      </Link>
                    </div>
                  </div>

                  <div class="col-md-8 col-sm-8 col-xs-12 hidden-xs">
                    <div class="middletext">
                      <h1>Become a part of something great Today</h1>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-4 col-xs-6 text-right">
                    <div class="logo1hafele">
                      <Link to="javascript:void();">
                        <img src={logo_mobile} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mobile-menu-area hidden-sm hidden-md hidden-lg">
              <div class="container">
                <div class="row">
                  <div class="mobile_logo">
                    <Link to="#">
                      <img src="img/logo/logo-mobile.png" alt="" />
                    </Link>
                  </div>
                  <div class="col-xs-12">
                    <div class="mobile-menu">
                      <nav>
                        <ul>
                          <li>
                            <Link to="#">
                              BECOME A PART OF SOMETHING GREAT TODAY
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="popup_sidebar_sec">
          {/* <div className="popup_sidebar_overlay"></div> */}
          <div className="widget_area">
            <Link to="" className="widget_closer">
              <i className="fa fa-times"></i>
            </Link>
            <div className="center_align">
              <div className="about_widget_area">
                <div className="wd_logo">
                  <Link to="#">
                    <img src={logo2} alt="" />
                  </Link>
                </div>
                <div className="wlcmehead">
                  <h3>Welcome</h3>
                  <p>Login to continue!</p>
                </div>
                <hr className="divhr" />
                <div className="theme-form">
                  <div className="form-group">
                    <label htmlFor="user id">User ID</label>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <span className="fa fa-user" aria-hidden="true"></span>
                      </span>
                      <input
                        className="form-control frm"
                        data-val="true"
                        placeholder="Enter User Id"
                        type="text"
                        value={mobile}
                        onChange={(event) => {
                          const input = event.target.value;
                          setMobile(input);
                          // if (/^\d*$/.test(input)) {
                          //   setMobile(input);
                          // }
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    {errors.mobile && (
                      <p className="login-error-message">{errors.mobile}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="review">Password</label>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <span className="fa fa-key" aria-hidden="true"></span>
                      </span>
                      <input
                        className="form-control frm"
                        placeholder="Enter Password"
                        type={showPassword === true ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="showeye" onClick={toggleShowPassword}>
                        <i className="fa fa-eye"></i>
                      </span>
                    </div>
                    {errors.password && (
                      <p className="login-error-message">{errors.password}</p>
                    )}
                  </div>
                  <div className="form-group mtop15">
                    <input
                      type="submit"
                      className="btn btn-primary custombtn"
                      id="btnSubmit"
                      onClick={handleLogin}
                    />
                    <Link
                      to="/forgot_password"
                      className="d-block login_closer"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="hero_cover_one mt128" id="myDiv">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="innerdiv">
                  <h1
                    class="animated fadeInDown wow animated brand"
                    data-wow-delay=".2s"
                  >
                    Get a chance to earn more!
                  </h1>
                  <div class="bdr"></div>
                  <div class="col-md-7 nopaddleft col-sm-7 col-xs-12 hidden-xs">
                    <figure class="combo">
                      <img
                        src={combo}
                        class="img-responsive"
                        alt="get color combo pack"
                      />
                    </figure>
                  </div>
                  {/* <div class="col-md-5 col-sm-5 col-xs-12">
										<div class="prdctsss hidden-xs">
											<h4 class="solutionoffer">Product & Solutions Offered</h4>
											<ul class="quotelist firstlist">
												<li>Door Solutions</li>
												<li>Furniture Hardware</li>
												<li>Sliding Solutions</li>
												<li>Kitchen Solutions </li>
												<li>Home Appliances</li>
												<li>Bathroom Solutions</li>
												<li>Furniture Lighting</li>
												<li>Interior Surfaces</li>
											</ul>
										</div>
									</div> */}
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                style={{ margiTop: "60px" }}
              >
                <form
                  action="https://hafelecup.in/landing?Length=11"
                  data-ajax="true"
                  data-ajax-method="Post"
                  enctype="multipart/form-data"
                  id="formAdd"
                  method="post"
                >
                  <input
                    name="__RequestVerificationToken"
                    type="hidden"
                    value="VZOE7bvszO_tYMANczxf4ubR0feoAU9xQ7jiJ94PFfoZCtHOosRllCbJ_yniPHSQrFQgHpGVbsfyy7r4h97GwqmXgLRducMBtV_x4kcbWjE1"
                  />
                  <input
                    id="pstbck"
                    name="PostbackType"
                    type="hidden"
                    value=""
                  />
                  <div class="enquiryForm" style={{ marginBottom: "20px" }}>
                    <div class="">
                      <h1 class="contacttag">
                        To Enroll please fill the below details
                      </h1>

                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span class="fa fa-user" aria-hidden="true"></span>
                          </span>
                          <input
                            autocomplete="nope"
                            class="form-control frm"
                            data-val="true"
                            data-val-required="Name Required"
                            id="txtName"
                            name="name"
                            placeholder="Your Name"
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                          />
                        </div>
                        {errors.name && (
                          <div className="error-message-landing">
                            {errors.name}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span
                              class="fa fa-mobile"
                              aria-hidden="true"
                            ></span>
                          </span>
                          <input
                            autocomplete="nope"
                            class="form-control frm"
                            data-val="true"
                            data-val-required="Mobile No. Required"
                            id="txtMobile"
                            maxlength="10"
                            minlength="10"
                            name="mobile"
                            placeholder="Mobile No"
                            type="text"
                            value={mobile2}
                            onChange={(event) => setMobile2(event.target.value)}
                          />
                        </div>
                        {errors.mobile2 && (
                          <div className="error-message-landing">
                            {errors.mobile2}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span
                              class="fa fa-envelope-o"
                              aria-hidden="true"
                            ></span>
                          </span>
                          <input
                            autocomplete="nope"
                            class="form-control frm"
                            data-val="true"
                            data-val-regex="Invalid E-Mail ID"
                            data-val-regex-pattern="^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$"
                            id="txtEmail"
                            name="email"
                            placeholder="Email (optional)"
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </div>
                        {errors.email && (
                          <div className="error-message-landing">
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span
                              class="fa fa-address-card"
                              aria-hidden="true"
                            ></span>
                          </span>
                          <select
                            class="form-control frm"
                            data-val="true"
                            onChange={handleStateChange}
														value={stateID}
                          >
                            <option value="">Select State</option>
                            {stateFetch.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.state && (
                          <div className="error-message-landing">
                            {errors.state}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span
                              class="fa fa-address-card"
                              aria-hidden="true"
                            ></span>
                          </span>

                          <select
                            class="form-control frm"
                            data-val="true"
                            data-val-required="District Required"
                            value={district}
                            // onChange={(event) => {
                            // 	setDistrict(event.target.value);
                            // 	salesDistrictData(event.target.value); // Call the function here
                            // }}
                            onChange={(event) => {
                              const selectedDistrict = districtFetch.find(
                                (item) => item.name === event.target.value
                              );

                              if (selectedDistrict) {
                                setDistrictID(selectedDistrict.id);
                                setDistrict(selectedDistrict.name);
                                salesDistrictData(selectedDistrict.id);
                              } else {
                                setDistrictID("");
                                setDistrict("");
                                setSalseDistrictData([]);
                                setSalesID("");
                                setSales("");
                              }
                            }}
                          >
                            <option value="">Select District</option>
                            {districtFetch.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.district && (
                          <div className="error-message-landing">
                            {errors.district}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <span
                              class="fa fa-user-circle"
                              aria-hidden="true"
                            ></span>
                          </span>

                          <select
                            class="form-control frm"
                            data-val="true"
                            data-val-required="Sales Required"
                            value={sales}
                            // onChange={(event) => {
                            // 	setDistrict(event.target.value);
                            // 	salesDistrictData(event.target.value); // Call the function here
                            // }}
                            onChange={(event) => {
                              const selectedSales = salseDistrictData.find(
                                (item) => item.name === event.target.value
                              );
                              if (selectedSales) {
                                setSalesID(selectedSales.id);
                                setSales(selectedSales.name);
                              } else {
                                setSalesID("");
                                setSales("");
                              }
                            }}
                          >
                            <option value="">Select Representative</option>
                            {salseDistrictData.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.sales && (
                          <div className="error-message-landing">
                            {errors.sales}
                          </div>
                        )}
                      </div>

                      <div class="callback">
                        <input
                          class="btn btn-primary landingsubmit"
                          type="submit"
                          onClick={register}
                          value="Submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <section class="blog-area  about-hafelep">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="all-single-blog">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="heading-banner-title">
                        <h4>
                          <span>Program Stages</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12 col-xs-12 programstage">
                      <img src={programstage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="blog-area  about-programp">
					<div class="container">
						<div class="row">
							<div class="col-md-12 col-sm-12 col-xs-12">
								<div class="all-single-blog">
									<div class="row">
										<div class="col-md-12">
											<div class="heading-banner-title">
												<h4><span>Product & Solutions Offered</span></h4>
											</div>
										</div>
									</div>


									<div class="row">
										<div class="col-lg-3 col-md-3 margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">

														<img src="/img/door_solutiion.png" />
													</div>
													<h4>Door Solutions</h4>

												</Link>
											</div>
										</div>
										<div class="col-lg-3 col-md-3 margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/furniture_hardware.png" />
													</div>
													<h4>Furniture Hardware</h4>

												</Link>
											</div>
										</div>
										<div class="col-lg-3 col-md-3 margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">

														<img src="/img/sliding_solutons.png" />

													</div>
													<h4>Sliding Solutions</h4>
												</Link>
											</div>
										</div>
										<div class="col-lg-3 col-md-3 margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/kitchen_solutions.png" />
													</div>
													<h4>Kitchen Solutions</h4>

												</Link>
											</div>
										</div>
										<div class="services-block-three col-lg-3 col-md-3 margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/homeappliance.png" />
													</div>
													<h4>Home Appliances</h4>
												</Link>
											</div>
										</div>
										<div class="services-block-three col-lg-3 col-md-3 sm-margin-30px-bottom xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/bathroom_solutions.png" />
													</div>
													<h4>Bathroom Solutions</h4>
												</Link>
											</div>
										</div>
										<div class="services-block-three col-lg-3 col-md-3 xs-margin-20px-bottom">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/furniture_lighting.png" />
													</div>
													<h4>Furniture Lighting</h4>
												</Link>
											</div>
										</div>
										<div class="services-block-three col-lg-3 col-md-3">
											<div class="services-block-three">
												<Link to="javascript:void(0)">
													<div class="padding-15px-bottom">
														<img src="/img/interior.png" />
													</div>
													<h4>Interior Surfaces</h4>
												</Link>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</section> */}
        <section class="blog-area about-programp">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="all-single-blog">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="heading-banner-title">
                        <h4>
                          <span>About Hafele Contractor Unnati Programme</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12 col-xs-12 aboutv">
                      <p>
                        The Hafele Contractor Unnati Programme is an attempt by
                        Hafele to reach out to you and other independent
                        influencers like Contractors, carpenters, Electricians
                        and Plumbers across the nation. In the hardware and
                        appliances value chain, you hold a key position in
                        influencing customer decisions, advising and guiding
                        them about the benefits of using Hafele products.
                        Through this program, we seek to build a relationship
                        with you and encourage you to promote Hafele products.
                      </p>
                      <p>
                        In addition to that, you will earn points each time you
                        scan a QR code on the eligible Hafele product
                        participating in the program. All accumulated points
                        will be eligible for redemption in the form of exciting
                        Hafele products, Instant Bank Transfers, Direct Paytm
                        wallet transfers and Digital vouchers listed in the
                        rewards catalogue.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="blog-area about-hafelep">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="all-single-blog">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="heading-banner-title">
                        <h4>
                          <span>About Hafele</span>{" "}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12 col-xs-12 aboutv">
                      <p>
                        Hafele India is a wholly-owned subsidiary of the Hafele
                        Global network and has been operating in India since
                        2003 under the leadership of Mr. Jürgen Wolf (Managing
                        Director). The ability of the company to understand the
                        diverse Indian market has made it an authority in the
                        field of architectural hardware, furniture and kitchen
                        fittings and accessories. The company also has a strong
                        presence in synergized product categories namely Home
                        Appliances, Furniture Lighting, Sanitary and Surfaces
                        catering to the focused demand from these industries.
                      </p>
                      <p>
                        The subsidiary has a strong nation-wide presence with
                        offices in Mumbai, Pune, Ahmedabad, Bangalore, Chennai,
                        Hyderabad, Delhi, Kolkata and Cochin. It has fullscale
                        operations in Sri Lanka and Bangladesh with Regional
                        offices and Design Showrooms in both the countries; and
                        has also spread its operations to other regions of South
                        Asia including Nepal, Bhutan and Maldives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="blog-area about-programp">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="all-single-blog">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="heading-banner-title">
                        <h4>
                          <span>Contact Us</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-4 col-xs-12">
                      <figure
                        class="infodetail"
                        style={{
                          minHeight: "245px",
                          border: "2px solid #efefef",
                        }}
                      >
                        <i class="fa fa-map-marker"></i>
                        <figcaption>
                          <h4>Hafele Contractor Unnati Programme</h4>
                          <address>
                            Hafele India Pvt Ltd <br />
                            Office No 3, Building A Beta,
                            <br />
                            I - Think Techno Campus,
                            <br />
                            Opp Kanjurmarg Station East, <br />
                            Mumbai - 400 042 India
                          </address>
                        </figcaption>
                      </figure>
                    </div>
                    <div class="col-md-4 col-xs-12">
                      <figure
                        class="infodetail"
                        style={{
                          minHeight: "245px",
                          border: "2px solid #efefef",
                        }}
                      >
                        <i class="fa fa-phone"></i>
                        <figcaption>
                          <h4>Timing</h4>
                          <address>
                            09:30AM to 5:30PM (Mon-Sat)
                            <br />
                            <Link to="tel:9289422000">9289422000</Link>
                          </address>
                        </figcaption>
                      </figure>
                    </div>
                    <div class="col-md-4 col-xs-12">
                      <figure
                        class="infodetail"
                        style={{
                          minHeight: "245px",
                          border: "2px solid #efefef",
                        }}
                      >
                        <i class="fa fa-envelope-o"></i>
                        <figcaption>
                          <h4 class="mail">Email</h4>
                          <address>info@hafelecup.in</address>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
