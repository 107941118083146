import React, { useState, useEffect } from "react";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import SalesHeader from "../common/SalesHeader";
import AMHeader from "../common/AMHeader";
import RMHeader from "../common/RMHeader";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { useFormik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

export default function RptSalesDistrictMap() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState("10");
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getReportData(
      {
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const resetData = () => {
    setFilterData({});
    searchFormik.resetForm();
    searchFormik.handleReset();
    getReportData(
      {
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  };

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `reports/salesDistrictMap/list?userType=SALES&sortBy=createDateTime&sortAsc=true${searchMobile}${stDate}${endDate}&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const download = (data) => {
    setLoading(true);
    setDownloadLoading(true);
    try {
      const searceMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          Constants.baseUrl +
            `reports/download/districtMapReport?userType=SALES${searceMobile}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "SalesDistrictMap_reports.csv");
            setDownloadLoading(false);
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
            setDownloadLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      name: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const areAllValuesBlank = () => {
    const { mobile, name, startDate, endDate } = searchFormik.values;
    return !mobile && !name && !startDate && !endDate;
  };

  let headerComponent;

  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (
    localStorage.getItem("user_type") === "RO" ||
    localStorage.getItem("user_type") === "HO" || localStorage.getItem("user_type") === "ADMIN"
  ) {
    headerComponent = <RMHeader />;
  }
  return (
    <>
      <div className="wrapper home-2">
        {headerComponent}
				{loading ? <Oval /> : null}
        <section className="blog-area pt182">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-single-blog rpt">
								<form onSubmit={searchFormik.handleSubmit}>
                    <div class="row">
                      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                          
                          <div className="col-md-4 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <input
                                class="form-control reset"
                                type="text"
                                placeholder="Mobile No."
                                id="mobile"
                                value={searchFormik.values.mobile}
                                onChange={(e) => {
                                  const numericValue = e.target.value
                                    .replace(/\D/g, "")
                                    .slice(0, 10);
                                  searchFormik.handleChange({
                                    target: {
                                      name: "mobile",
                                      value: numericValue,
                                    },
                                  });
                                }}
                              />
                              {getSearchFormErrorMessage("mobile")}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 col-xs-12">
                            <DatePicker
                              id="startDate"
                              selected={searchFormik.values.startDate}
                              onChange={(date) =>
                                searchFormik.setFieldValue("startDate", date)
                              }
                              style={{ zIndex: 999 }}
                              dateFormat="dd-MM-yyyy"
                              className="form-control calnder"
                              showYearDropdown
                              showMonthDropdown
                              autoComplete="off"
                              scrollableYearDropdown
                              maxDate={new Date()}
                              onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                              onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                              onClick={(e) => e.preventDefault()} // Preven
                              placeholderText="From date"
                            />
                            {getSearchFormErrorMessage("startDate")}
                          </div>
                          <div className="col-md-4 col-sm-12 col-xs-12">
                            <DatePicker
                              id="endDate"
                              selected={searchFormik.values.endDate}
                              onChange={(date) =>
                                searchFormik.setFieldValue("endDate", date)
                              }
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              showYearDropdown
                              showMonthDropdown
                              autoComplete="off"
                              scrollableYearDropdown
                              maxDate={new Date()}
                              onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                              onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                              onClick={(e) => e.preventDefault()} // Preven
                              placeholderText="To date"
                            />
                            {getSearchFormErrorMessage("endDate")}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div class="d-flex">
                          <button
                            type="submit"
                            class="btn login_btn up-side"
                            //disabled={areAllValuesBlank()}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            class="btn login_btn up-side"
                            onClick={() => resetData()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-single-blog rpt">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="carrd1">
                        <div className="card-header card-header-warning">
                          <h4 className="card-title">
                            Sales District Map{" "}
                            {reportData === null ? (
                              ""
                            ) : (
                              <Link
                                to="#"
                                class={`pull-right ${
                                  downloadLoading ? "btn-opacity" : ""
                                }`}
                                onClick={() => download(searchFormik.values)}
                              >
                                {downloadLoading ? (
                                  <span>
                                    {" "}
                                    <i class="fa fa-spinner fa-spin"></i>Export
                                  </span>
                                ) : (
                                  " Export"
                                )}
                              </Link>
                            )}
                          </h4>
                        </div>
                        <div className="card-body table-responsive">
                          <div className="table table-striped mb_01">
                            <table
                              id="gridData"
                              className="table table-bordered mytable"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Sr.No.</th>
                                  <th>District Id</th>
                                  <th>City Id</th>
                                  <th>City Name</th>
                                  <th>State Id</th>
                                  <th>State Name</th>
                                  <th>Sales Executive Id</th>
                                  <th>Sales Executive Name</th>
                                  <th>Sales Executive Mobile No</th>
                                </tr>
                              </thead>
                              {reportData === null ? (
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="9"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "16px",
                                      }}
                                    >
                                      No record found!
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  {reportData &&
                                    reportData.map((item, index) => {
                                      
																		// const printDate = item[0]
                                    // ? moment(item[0]).format(
                                    //     "DD-MM-YYYY"
                                    //   )
                                    // : "";

                                      const serialNumber =
                                        (currentPage - 1) * limit + index + 1;
                                      return (
                                        <>
                                          <tr key={item.id}>
                                            <td>{serialNumber}</td>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                            <td>{item[2]}</td>
                                            <td>{item[3]}</td>
                                            <td>{item[4]}</td>
                                            <td>{item[5]}</td>
                                            <td>{item[6]}</td>
                                            <td>{item[7]}</td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              )}
                            </table>
                            {totalPages > 1 && (
                              <ResponsivePagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={(page) => {
                                  setCurrentPage(page);
                                  getReportData(filterData, page);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
