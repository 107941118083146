import React, { useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import logo2 from "../../assets/img/logo/logo2.png";
import logo_mobile from "../../assets/img/logo/logo-mobile.png";
import { Link } from "react-router-dom";
import artical_list from "../../assets/img/article_list.pdf";
import { useNavigate } from "react-router-dom";
export default function AMHeader() {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [articalurl, setArticalurl] = useState("");

  useEffect(() => {
    getProgramData();
  }, []);
  const getProgramData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/child/1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              // appVersion: Constants.av,
              // platform: Constants.pt,
              // company: Constants.company,
              // program: Constants.program,
            },
          },
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setArticalurl(dt?.output?.articleList);
          }
          
        })
        .catch((err) => {});
    }
  };
  
  const logout = () => {
    localStorage.clear();
    // window.location = '/'
  };
  return (
    <>
      <header>
        <div className="header-container header-sticky sticky">
          <div className="header-top-area">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-5 col-xs-12 text-left">
                  <div className="header-top-menu">
                    <ul>
                      <li className="">
                        <Link to="mailto:info@hafelecup.in">
                          <i className="fa fa-envelope-o"></i> info@hafelecup.in
                        </Link>
                      </li>
                      <li className="support">
                        <Link to="tel:+919289422000">
                          <i className="fa fa-phone"></i>+91 9289422000{" "}
                        </Link>
                      </li>
                      <li className="support hidden-xs">
                        <span>
                          {" "}
                          <i className="fa fa-clock-o"></i> 09.30 AM – 05.30 PM{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-7 col-sm-7 col-xs-12 text-right">
                  <div className="header-top-menu">
                    <ul>
                      {/* <li className="support hidden-xs"><Link to="/registration"><span><i className="fa fa-pencil-square-o"></i> New Registration </span></Link></li>
                                            <li className="support hidden-xs"><Link to="/pending_reg"><span><i className="fa fa-file-text-o"></i> Pending Registration </span></Link></li> */}
                      <li className="support">
                        <span>
                          <i className="fa fa-user"></i> Welcome :{" "}
                          <span className="name_text">
                            {localStorage.getItem("user_name")} 
                            {/* {(() => {
                              let userType = localStorage.getItem("user_type");
                              if (userType === "SH") {
                                return "AM";
                              } else if (userType === "RO") {
                                return "RM";
                              } else if (userType === "SALES") {
                                return "TM";
                              } else if (userType === "PRD") {
                                return "Plant Administrator";
                              } else {
                                return userType;
                              }
                            })()} */}
                          </span>{" "}
                        </span>
                      </li>
                      <li className="account" onClick={logout}>
                        <Link to="/">
                          <i className="fa fa-sign-in"></i> Log Out{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-middel-area hidden-xs">
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-sm-2 col-xs-6">
                  <div className="logo logo1">
                    <Link to="#">
                      <img src={logo2} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-12 hidden-xs">
                  <div className="main-menu-area">
                    <nav>
                      <ul className="main-menu">
                        <li>
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li>
                          <Link to="">verify</Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/verify_anomaly">Anomaly</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="">Approve </Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/registration_approval">Registration</Link>
                            </li>
                            
                          </ul>
                        </li>
                        {/* <li><Link to="/ticket_report">Ticket Report</Link></li> */}
                        <li>
                          <Link to="/registration_approved">
                            Registered User
                          </Link>
                        </li>
                        <li>
                          <Link to={articalurl} target="_blank">
                            Article List
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
            <div className="container">
              <div className="row">
                <div className="mobile_logo">
                  <Link to="#">
                    <img src={logo_mobile} alt="" />
                  </Link>
                </div>
                <div className="col-xs-12">
                  <div className="mobile-menu">
                    <nav>
                      <ul className="main-menu">
                        <li>
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li>
                          <Link to="">verify</Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/verify_anomaly">Anomaly</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="">Approve </Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/registration_approval">Registration</Link>
                            </li>
                            
                          </ul>
                        </li>
                        {/* <li>
                          <Link to="/ticket_report">Ticket Report</Link>
                        </li> */}
                        <li>
                          <Link to="/registration_approved">
                            Registered User
                          </Link>
                        </li>
                        <li>
                          <Link to={artical_list} target="_blank">
                            Article List
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
