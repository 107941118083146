import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from "axios";
import * as Constants from "../../Constants/index";
// import swal from 'sweetalert';
export default function DonutChart() {
  useEffect(() => {
    let root = am5.Root.new("chartdiv2");
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(60),
        innerRadius: am5.percent(50),
        layout: root.horizontalLayout,
      })
    );

    // Fetch data from API
    const fetchData = async () => {
      let token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(
            Constants.baseUrl + `capture/qr/product_report_trend`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                program: Constants.program,
                company: Constants.company,
              },
            }
          );
          const apiData = response.data.output;
          let colors = [
            "#9d258f",
            "#e31b6d",
            "#ff7200",
            "#00a1de",
            "#6fbc23",
            "#ffb400",
          ]; // Add more colors if needed

          // Process API data and create the chart data
          let data = apiData.map((item, index) => ({
            name: item.category,
            quantity: item.total_scan,
            color: am5.color(colors[index % colors.length]),
          }));
					
          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              name: "Series",
              valueField: "quantity",
              categoryField: "name",
            })
          );


          series
            .get("colors")
            .set("colors", [
              am5.color(0xb30000),
              am5.color(0x8d8d8d),
              am5.color(0xf3f3f3),
              am5.color(0xb30000),
            ]);
          series.data.setAll(data);

          series.labels.template.set("visible", false);
          series.ticks.template.set("visible", false);

          series.slices.template.set("strokeOpacity", 0);
          series.slices.template.set(
            "fillGradient",
            am5.RadialGradient.new(root, {
              stops: [
                { brighten: -0.8 },
                { brighten: -0.8 },
                { brighten: -0.5 },
                { brighten: 0 },
                { brighten: -0.5 },
              ],
            })
          );

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerY: am5.percent(50),
              y: am5.percent(50),
              layout: root.verticalLayout,
            })
          );

          legend.valueLabels.template.setAll({ textAlign: "bottom" });
          legend.labels.template.setAll({
            maxWidth: 160,
            width: 120,
            oversizedBehavior: "wrap",
          });

          legend.data.setAll(series.dataItems);

          series.appear(1000, 100);

          root.current = root;
        } catch (error) {
          console.log(error);
        }
      } else {
      }
    };

    fetchData();

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <>
      <div className="col-md-4 col-xs-12">
        <div id="chartdiv2" style={{ width: "100%", height: "250px" }}></div>
      </div>
    </>
  );
}
