import React, { useEffect, useState } from 'react'
import logo2 from '../../assets/img/logo/logo2.png'
import logo_mobile from '../../assets/img/logo/logo-mobile.png'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery';
import axios from 'axios';
import * as Constants from '../../Constants/index'
import swal from 'sweetalert';
import { NavItem } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';

export default function HomeHeader() {
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()



    useEffect(() => {

        const userId = localStorage.getItem('user_id');
        if (userId) {
            // swal('You are already logged in!');
            //window.location = '/dashboard'
            navigate('/dashboard')
        }
        $('.navigator, login_show').on('click', function (e) {
            e.preventDefault();
            $('.popup_sidebar_sec').toggleClass('active');
        });
        $('.popup_sidebar_overlay, .widget_closer, .login_closer').on('click', function () {
            $('.popup_sidebar_sec').removeClass('active');
        });

    }, [])

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } 
        
        // else if (!/^\d+$/.test(mobile)) {
        //     errors.mobile = 'User ID should be numeric';
        // } else if (mobile.length !== 10) {
        //     errors.mobile = 'User ID should be 10 digits';
        // }

        if (!password) {
            errors.password = 'Password is required';
        }

        return errors;
    };

    const handleLogin = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        } 
        
        try {
            await axios.post(Constants.baseUrl + "auth/login-password", {
                mobile: mobile,
                password: password

            }, {
                headers: {
                    "Accept-Language": localStorage.getItem("language"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {

                            if(res.data.output.user.userType == 'MEMBER' || res.data.output.user.userType == 'SHO') {
                                swal({
                                    text: 'You are not authorized, Please contact admin',
                                    icon: 'error',
                                    timer: 3000,
                                    buttons: false,
                                });
                            } else {
                                localStorage.setItem('user_id', (res.data.output.user.id))
                                localStorage.setItem('user_type', (res.data.output.user.userType))
                                localStorage.setItem('user_name', (res.data.output.user.name))
                                localStorage.setItem('user_email', (res.data.output.user.email))
                                localStorage.setItem('user_mobile', (res.data.output.user.mobile))
                                localStorage.setItem('token', (res.data.output.access.token))
                                // window.location = '/dashboard'
                                navigate('/dashboard')
                            }
                        } else if (res.data.code === 16001) {
                            swal({
                                text: res.data.msg,
                                // icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal(res.data.msg)
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    const toggleShowPassword = () => {
        if (showPassword === false) {
            setShowPassword(true)
        } else {
            setShowPassword(false)
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };
    return (
        <>
            <header>
                <div className="header-container header-sticky sticky header-nav">
                    <div className="header-top-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="header-top-menu text-left">
                                        <ul>
                                            <li className="">
                                                <Link to="mailto:info@hafelecup.in">
                                                    <i className="fa fa-envelope-o"></i> info@hafelecup.in
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8  text-right">
                                    <div className="header-top-menu">
                                        <ul>
                                            <li className="support"><Link to="tel:+919289422000"><i className="fa fa-phone"></i> +91 9289422000 </Link></li>
                                            <li className="support hidden-xs"><span> <i className="fa fa-clock-o"></i> 09:30 AM to 05:30 PM </span></li>
                                            {
                                                showForgotPassword === false ?
                                                    <li className="account">
                                                        <Link to="#" className="navigator"><i className="fa fa-sign-in"></i> LOG IN </Link>
                                                    </li> : ''}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-middel-area hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <div className="logo logo1">
                                        <Link to="/"><img src={logo2} alt="" /></Link>
                                    </div>
                                </div>
                                {showForgotPassword === false ?
                                    <div className="col-md-10 col-sm-10 col-xs-12 hidden-xs">
                                        <div className="main-menu-area">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to="/" >Home</Link></li>
                                                    <li><Link to="/about_hafele">About Hafele</Link></li>
                                                    <li><Link to="/about_program">About Program</Link></li>
                                                    <li><Link to="/contact">Contact Us</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div> :
                                    <div className="col-md-10 col-sm-10 col-xs-12 hidden-xs">
                                        <div className="main-menu-area">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to="/" onClick={() => setShowForgotPassword(false)}>Home</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
                        <div className="container-fluid pl-0 pr-0">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="mobile-menu">
                                        {/* <nav>
                                            <ul>
                                                <li><Link to="/" >Home</Link></li>
                                                <li><Link to="/about_hafele">About Hafele</Link></li>
                                                <li><Link to="/about_program">About Program</Link></li>
                                                <li><Link to="/contact">Contact Us</Link></li>
                                            </ul>
                                        </nav> */}


                                        <Navbar expand="lg" className="bg-body-tertiary">
                                            <Navbar.Brand to="#home">
                                                <Link to="#">
                                                    <img src={logo_mobile} alt="" /></Link>
                                            </Navbar.Brand>
                                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                            <Navbar.Collapse id="basic-navbar-nav">
                                                <nav>
                                                    <ul className="main-menu">
                                                        <li>
                                                            <Link to="/">
                                                                Home</Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/about_hafele">
                                                                About Hafele</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about_program">
                                                                About Program</Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/contact">
                                                                Contact Us</Link>
                                                        </li>




                                                    </ul>
                                                </nav>
                                            </Navbar.Collapse>
                                        </Navbar>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div></div>
            <section className="popup_sidebar_sec">
                {/* <div className="popup_sidebar_overlay"></div> */}
                <div className="widget_area">
                    <Link to="" className="widget_closer"><i className="fa fa-times"></i></Link>
                    <div className="center_align">
                        <div className="about_widget_area">
                            <div className="wd_logo">
                                <Link to="#">
                                    <img src={logo2} alt="" />
                                </Link>
                            </div>
                            <div className="wlcmehead">
                                <h3>Welcome</h3>
                                <p>Login  to continue!</p>
                            </div>
                            <hr className="divhr" />
                            <div className="theme-form">
                                <div className="form-group">
                                    <label htmlFor="user id">User ID</label>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <span className="fa fa-user" aria-hidden="true"></span>
                                        </span>
                                        <input className="form-control frm" data-val="true" placeholder="Enter User Id" type="text" value={mobile} onChange={(event) => {
                                            const input = event.target.value;
                                            setMobile(input);
                                            // if (/^\d*$/.test(input)) {
                                            //     setMobile(input);
                                            // }
                                        }} onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.mobile && <p className='login-error-message'>{errors.mobile}</p>}


                                </div>
                                <div className="form-group">
                                    <label htmlFor="review">Password</label>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <span className="fa fa-key" aria-hidden="true"></span>
                                        </span>
                                        <input className="form-control frm" placeholder="Enter Password" type={showPassword === true ? "text" : "password"} value={password} onChange={(event) => setPassword(event.target.value)} onKeyDown={handleKeyDown} />
                                        <span className="showeye" onClick={toggleShowPassword}>
                                            {showPassword === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                        </span>
                                    </div>
                                    {errors.password && <p className='login-error-message'>{errors.password}</p>}
                                </div>
                                <div className="form-group mtop15">
                                    <input type="submit" className="btn btn-primary custombtn" id="btnSubmit" onClick={handleLogin} />
                                    <Link to="/forgot_password" className="d-block login_closer">Forgot Password?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
