import React from 'react'
import HomeHeader from './HomeHeader'
import Footer from '../common/Footer'

export default function HomeAboutProgram() {
    return (
        <>
            <div className="wrapper home-2">
                <HomeHeader />
                <div id="divAboutProgram">
                    <section className="blog-area pt70 min570">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="all-single-blog">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="heading-banner-title">
                                                    <h4>About Program</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-md-12 col-xs-12 aboutv">
                                                <p>The Hafele Contractor Unnati Programme is an attempt by Hafele to reach out to you and other independent influencers like Contractors, carpenters, Electricians and Plumbers across the nation. In the hardware and appliances value chain, you hold a key position in influencing customer decisions, advising and guiding them about the benefits of using Hafele products. Through this program, we seek to build a relationship with you and encourage you to promote Hafele products.
                                                </p>
                                                <p>
                                                    In addition to that, you will earn points each time you scan a QR code on the eligible Hafele product participating in the program. All accumulated points will be eligible for redemption in the form of exciting Hafele products, Instant Bank Transfers, Direct Paytm wallet transfers and Digital vouchers listed in the rewards catalogue.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}
