import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import swal from "sweetalert";
import * as Constants from "../../Constants/index";
export default function HomeBanner() {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const banner = async () => {
      try {
        await axios
          .get(Constants.baseUrl + "web/banner", {
            headers: {
              // Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          })
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                console.log(res);
                setResults(res.data.output.results);
              } else if (res.data.code === 12002) {
                // swal({
                //     text: res.data.msg,
                //     // icon: 'error',
                //     timer: 2000,
                //     buttons: false,
                // })
              }
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    banner();
  }, []);

  return (
    <>
      <div id="divHome">
        <Carousel autoPlay={true} infiniteLoop={true}>
          {results.map((item, index) => {
            return (
              <>
                <div>
                  <img src={item.url} alt="" />
                </div>
              </>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}
