import React, { useState, useEffect } from 'react'
import Footer from './common/Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SalesHeader from './common/SalesHeader';
import AMHeader from './common/AMHeader';
import RMHeader from './common/RMHeader';
export default function RptGeneratedCoupon() {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    let headerComponent;

    if (localStorage.getItem('user_type') === 'SALES') {
        headerComponent = <SalesHeader />;
    } else if (localStorage.getItem('user_type') === 'SH') {
        headerComponent = <AMHeader />;
    } else if (localStorage.getItem('user_type') === 'RO') {
        headerComponent = <RMHeader />;
    }
    return (
        <>
            <div className="wrapper home-2">
                {headerComponent}
                <section className="blog-area pt182">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">

                                <div className="all-single-blog rpt">
                                    <div className="row clearfix callwell mb-15">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Year </label>
                                                <select className="form-control2" id="ddlyear" name="Year" style={{ alignItem: 'center' }}>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Month </label>
                                                <select className="form-control2" id="ddlmonth" name="Month" style={{ alignItem: 'center' }}>
                                                    <option value="1">Jan</option>
                                                    <option value="2">Feb</option>
                                                    <option value="3">Mar</option>
                                                    <option value="4">Apr</option>
                                                    <option value="5">May</option>
                                                    <option value="6">Jun</option>
                                                    <option value="7">Jul</option>
                                                    <option value="8">Aug</option>
                                                    <option value="9">Sep</option>
                                                    <option value="10">Oct</option>
                                                    <option value="11">Nov</option>
                                                    <option value="12">Dec</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label className="invisible" style={{ width: '100%' }}>hidden</label>
                                                <input type="submit" className="btn login_btn" value="Search" id="btnSearch" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="carrd1">
                                                <div className="card-header card-header-warning">
                                                    <h4 className="card-title">Warehouse Printing Data Report  <Link className="fa fa-file-excel-o pull-right" to=""> Export</Link></h4>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger">
                                                            NO RECORDS FOUND
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            </div>
        </>
    )
}
