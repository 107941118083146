import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo2 from '../assets/img/logo/logo2.png'
import logo_mobile from '../assets/img/logo/logo-mobile.png'
import Footer from './common/Footer'
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
export default function ChangePassword() {
    const [otp, setOtp] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordCon, setShowPasswordCon] = useState(false)
    const [phoneNum, setPhoneNum] = useState('')


    const validateForm = () => {
        let errors = {};
        if(!phoneNum){
            errors.phoneNum = 'Phone Number is required'
        }
        else if(!otp) {
            errors.otp = 'OTP is required';
        } else if (!/^\d+$/.test(otp)) {
            errors.otp = 'OTP should be not matched';
        } else if (otp.length !== 4) {
            errors.mobile = 'OTP should be 4 digits';
        }
        if (!newPass) {
            errors.newPass = 'New password required';
        } else if (newPass.length < 6) {
            errors.newPass = 'New password should be 6 characters long';
        }
        if (!confirmPass) {
            errors.confirmPass = 'Confirm password required';
        } else if (confirmPass !== newPass) {
            errors.confirmPass = 'Confirm password should be match';
        }


        return errors;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }

        try {
            await axios.post(Constants.baseUrl + 'auth/verify-otp/setPassward',
                {
                    otp: otp,
                    passward: newPass,
                    mobile: phoneNum
                },
                {
                    headers: {
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            })
                            setTimeout(() => {
                                window.location = '/'
                            }, 2000);
                        } else if (res.data.code === 12002) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });

                        }
                        else if(res.data.code === 12001){
                            swal({text: res.data.msg, buttons: false,
                                timer: 2000,})
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleChange = (event) => {
        const input = event.target.value;
    
        // Allow only numbers and restrict length to 10
        if (/^\d*$/.test(input) && input.length <= 10) {
          setPhoneNum(input);
        }
      };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    const ShowNewPass = () => {
        if (showPassword === false) {
            setShowPassword(true)
        } else {
            setShowPassword(false)
        }
    }
    const showConfirmPass = () => {
        if (showPasswordCon === false) {
            setShowPasswordCon(true)
        } else {
            setShowPasswordCon(false)
        }
    }
    return (
        <>
            <header>
                <div className="header-container header-sticky sticky header-nav">
                    <div className="header-top-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="header-top-menu text-left">
                                        <ul>
                                            <li className="">
                                                <Link to="mailto:info@hafelecup.in">
                                                    <i className="fa fa-envelope-o"></i> info@hafelecup.in
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8  text-right">
                                    <div className="header-top-menu">
                                        <ul>
                                            <li className="support"><Link to="tel:+919289422000"><i className="fa fa-phone"></i> +91 9289422000 </Link></li>
                                            <li className="support hidden-xs"><span> <i className="fa fa-clock-o"></i> 09:30 AM to 05:30 PM </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-middel-area hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <div className="logo logo1">
                                        <Link to="#"><img src={logo2} alt="" /></Link>
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-10 col-xs-12 hidden-xs">
                                    <div className="main-menu-area">
                                        <nav>
                                            <ul className="main-menu">
                                                <li><Link to="/" >Home</Link></li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
                        <div className="container">
                            <div className="row">
                                <div className="mobile_logo">
                                    <Link to="#"><img src={logo_mobile} alt="" /></Link>
                                </div>
                                <div className="col-xs-12">
                                    <div className="mobile-menu">
                                        <nav>
                                            <ul>
                                                <li><Link to="/" >Home</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section class="blog-area  min570">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog">
                                <div class="row clearfix">
                                    <div class="col-md-12 col-xs-12 aboutv">
                                        <div class="user_card">
                                            {/* <div class="justify-content-center">
                                                <div class="brand_logo_container">
                                                    Forgot Password
                                                </div>
                                            </div> */}
                                            <div class="justify-content-center form_container">
                                                <div class="window1 password theme-form">
                                                <div class="input-group mb15">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                                                        </div>
                                                        <input autoComplete="nope" class="form-control form-control1" placeholder="Phone Number" type="text" maxLength='10' minLength='0' value={phoneNum} onChange={handleChange} onKeyDown={handleKeyDown}/>
                                                    </div>
                                                    {errors.phoneNum && (
                                                        <div className="error-message error-login">{errors.phoneNum}</div>
                                                    )}
                                                    <div class="input-group mb15">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-key"></i></span>
                                                        </div>
                                                        <input autoComplete="nope" class="form-control form-control1" placeholder="Enter OTP" type="text" maxLength='4' value={otp} onChange={(event) => {
                                                            const input = event.target.value;
                                                            if (/^\d*$/.test(input)) {
                                                                setOtp(input);
                                                            }
                                                        }} />
                                                    </div>
                                                    {errors.otp && (
                                                        <div className="error-message error-login">{errors.otp}</div>
                                                    )}
                                                    <div class="input-group mb15">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                                                        </div>
                                                        <input autoComplete="nope" class="form-control form-control1" placeholder="New Password" type={showPassword === true ? "text" : "password"} value={newPass} onChange={(event) => setNewPass(event.target.value)} />
                                                        <span className="showeye" onClick={ShowNewPass}>
                                                            {showPassword === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                        </span>
                                                    </div>
                                                    {errors.newPass && (
                                                        <div className="error-message error-login">{errors.newPass}</div>
                                                    )}
                                                    <div class="input-group mb15">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                                                        </div>
                                                        <input autoComplete="nope" class="form-control form-control1" placeholder="Confirm Password" type={showPasswordCon === true ? "text" : "password"} value={confirmPass} onChange={(event) => setConfirmPass(event.target.value)} />
                                                        <span className="showeye" onClick={showConfirmPass}>
                                                            {showPasswordCon === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                        </span>
                                                    </div>
                                                    {errors.confirmPass && (
                                                        <div className="error-message error-login">{errors.confirmPass}</div>
                                                    )}
                                                    <div class="d-flex justify-content-center mt-3 login_container">
                                                        <input id="btnsubmit" class="btn btn-primary custombtn" type="submit" value="Submit" onClick={handleSubmit} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
