import React from 'react'
import HomeHeader from './home/HomeHeader'
import HomeBanner from './home/HomeBanner'
import Footer from './common/Footer'

export default function Home() {
    return (
        <>
            <div className="wrapper home-2">
                <HomeHeader />
                <HomeBanner />
                <Footer />
            </div>
        </>
    )
}
