import React, { useState, useEffect } from "react";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SalesHeader from "./common/SalesHeader";
import AMHeader from "./common/AMHeader";
import RMHeader from "./common/RMHeader";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import ResponsivePagination from "react-responsive-pagination";
import { saveAs } from "file-saver";
import Oval from "./loader/CircleLoade";
import moment from "moment";
import 'react-responsive-pagination/themes/classic.css';

export default function RegistraionApproved() {
  const [isVisible, setIsVisible] = useState(false);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [searchUser, setSearchUser] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    registrationData(searchUser);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage]);

  const registrationData = async (userName) => {
    let token = localStorage.getItem("token");
    const searchUserVal = userName ? `&mobile=${userName}` : "";
    try {
      setLoading(true);
      const response = await axios.get(
        Constants.baseUrl +
          `user/usersBySalesTeam/list?userType=MEMBER${searchUserVal}&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        }
      );
      setLoading(false);
      if (response.status === 201) {
        setResults(response.data.output.results || []);
        setTotalPages(response.data.output.totalPages || 1);
      } else {
        swal({
          text: response.data.msg,
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsVisible(scrollTop > 200);
  };

  const download = async () => {
    let token = localStorage.getItem("token");
    const searchUserVal = searchUser ? `&mobile=${searchUser}` : "";
    try {
      setLoading(true);
      const response = await axios.get(
        Constants.baseUrl +
          `user/download/usersBySalesTeam?userType=MEMBER&status=APPROVED${searchUserVal}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
          responseType: "blob",
        }
      );
      setLoading(false);
      if (response.status === 200) {
        const fileData = new Blob([response.data], { type: "text/csv" });
        saveAs(fileData, "registered_user.csv");
      } else {
        swal({
          text: response.data.msg,
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSearchQuery = (event) => {
    const inputValue = event.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setSearchUser(inputValue);
      setCurrentPage(1); // Reset to the first page on new search
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      registrationData(searchUser);
    }
  };

  let headerComponent;
  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (["RO", "HO", "ADMIN"].includes(localStorage.getItem("user_type"))) {
    headerComponent = <RMHeader />;
  }

  return (
    <>
      {loading && <Oval />}
      {headerComponent}
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Influencer Mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        value={searchUser}
                        onChange={handleSearchQuery}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label className="invisible" style={{ width: "100%" }}>
                        hidden
                      </label>
                      <input
                        id="btnsubmit"
                        className="btn login_btn up-side"
                        type="submit"
                        value="Submit"
                        disabled={searchUser.trim() === ""}
                        onClick={() => registrationData(searchUser)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title">
                          Registered User
                          {results.length > 0 && (
                            <Link
                              to="#"
                              className="pull-right"
                              onClick={download}
                            >
                              <i className="fa fa-file-excel-o"></i> Export
                            </Link>
                          )}
                        </h4>
                      </div>
                      <div className="card-body table-responsive">
                        <div className="table table-striped mb_01">
                          <table
                            id="gridData"
                            className="table table-bordered mytable"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th scope="col">Sr.No.</th>
                                <th scope="col">Regional Manager</th>
                                <th scope="col">Sales Person Name</th>
                                <th scope="col">Sales Person Mobile</th>
                                <th scope="col">Influencer Name</th>
                                <th scope="col">Influencer Mobile</th>
                                <th scope="col">Influencer Email</th>
                                <th scope="col">Added On</th>
                              </tr>
                            </thead>
                            {results.length === 0 ? (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan="8"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "16px",
                                    }}
                                  >
                                    No record found!
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {results.map((item, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;
                                  const createDate = item[9]
                                    ? moment(item[9]).format(
                                        "DD-MM-YYYY h:mm A"
                                      )
                                    : "";
                                  return (
                                    <tr key={index}>
                                      <td>{serialNumber}</td>
                                      <td>{item[0]}</td>
                                      <td>{item[4]}</td>
                                      <td>{item[5]}</td>
                                      <td>{item[6]}</td>
                                      <td>{item[7]}</td>
                                      <td>{item[8]}</td>
                                      <td>{createDate}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                          {totalPages > 1 && (
                            <ResponsivePagination
                              current={currentPage}
                              total={totalPages}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Link
        id="scrollUp"
        to=""
        className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
        style={{ position: "fixed", zIndex: "2147483647" }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
    </>
  );
}
