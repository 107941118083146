import React, { useState, useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import active from "../../assets/img/active.png";
import inactive from "../../assets/img/inactive.png";
import dormant from "../../assets/img/dormant.png";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import ReactApexChart from "react-apexcharts";

export default function Influencer() {
  const [activeMember, setActiveMember] = useState(0);
  const [inActiveMember, setInActiveMember] = useState(0);
  const [dormantMember, setDormantMember] = useState(0);
  const colors = ["#34a603", "#858585", "#d20a28"];

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        axios
          .get(Constants.baseUrl + `program/dashboard/sales`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              platform: Constants.pt,
              appVersion: Constants.av,
              program: Constants.program,
            },
          })
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                setActiveMember(parseFloat(res.data.output?.memberActiveIgnoreDormant));
                setInActiveMember(parseFloat(res.data.output?.memberInActive));
                setDormantMember(parseFloat(res.data.output?.memberDormant));
              } else if (res.data.code === 12002) {
                // swal({
                //     text: res.data.msg,
                //     // icon: 'error',
                //     timer: 2000,
                //     buttons: false,
                // })
              }
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  }, []);

  const chartOptions = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        export: {
          csv: {
            filename: "chart-data",
            columnDelimiter: ",",
            headerCategory: "Category",
            headerValue: "Value",
          },
          svg: {
            filename: "chart-data",
          },
          png: {
            filename: "chart-data",
          },
        },
        autoSelected: "zoom",
      },
      events: {
        click: (chart, w, e) => {
          // console.log(chart, w, e)
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "75%",
        distributed: true,
        // borderRadius: 10 // Set the border radius for the bars
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        ["Active,"],
        ["Inactive"],
        ["Dormant", "(no activity >90 days)"],
      ],
      labels: {
        style: {
          colors: colors,
          fontSize: "11px",
        },
      },
    },
  };

  // const chartOptions = {
  //   chart: {
  //     height: 350,
  //     type: "bar",
  //     events: {
  //       click: (chart, w, e) => {
  //         // console.log(chart, w, e)
  //       },
  //     },
  //   },
  //   colors: colors,
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "75%",
  //       distributed: true,
  //       // borderRadius: 10 // Set the border radius for the bars
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   xaxis: {
  //     categories: [
  //       ["Active,"],
  //       ["Inactive"],
  //       ["Dormant", "(no activity >90 days)"],
  //     ],
  //     labels: {
  //       style: {
  //         colors: colors,
  //         fontSize: "11px",
  //       },
  //     },
  //   },
  // };

  const chartSeries = [
    {
      data: [activeMember, inActiveMember, dormantMember],
    },
  ];

  // useEffect(() => {
  //     if (activeMember != null) {
  //         am5.ready(function () {

  //             // Create root element
  //             var root = am5.Root.new("chartdiv1");

  //             // Set themes
  //             root.setThemes([
  //                 am5themes_Animated.new(root)
  //             ]);

  //             // Create chart
  //             var chart = root.container.children.push(am5xy.XYChart.new(root, {
  //                 panX: false,
  //                 panY: false,
  //                 wheelX: "none",
  //                 wheelY: "none"
  //             }));

  //             // Add cursor
  //             var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  //             cursor.lineY.set("visible", false);

  //             // Create axes
  //             var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
  //             var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
  //                 maxDeviation: 0,
  //                 categoryField: "Cateogry",
  //                 renderer: xRenderer,
  //                 tooltip: am5.Tooltip.new(root, {})
  //             }));

  //             xRenderer.grid.template.set("visible", false);

  //             var yRenderer = am5xy.AxisRendererY.new(root, {});
  //             var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
  //                 maxDeviation: 0,
  //                 min: 0,
  //                 extraMax: 0.1,
  //                 renderer: yRenderer
  //             }));

  //             yRenderer.grid.template.setAll({
  //                 strokeDasharray: [2, 2]
  //             });

  //             // Create series
  //             var series = chart.series.push(am5xy.ColumnSeries.new(root, {
  //                 Cateogry: "Series 1",
  //                 xAxis: xAxis,
  //                 yAxis: yAxis,
  //                 valueYField: "member",
  //                 sequencedInterpolation: true,
  //                 categoryXField: "Cateogry",
  //                 tooltip: am5.Tooltip.new(root, { dy: -25, labelText: "{valueY}" })
  //             }));

  //             series.columns.template.setAll({
  //                 cornerRadiusTL: 10,
  //                 cornerRadiusTR: 10,
  //                 strokeOpacity: 0
  //             });

  //             // series.set("fill", am5.color(0xff0000)); // set Series color to red
  //             // series.set("fill", am5.color('#34a603'));

  //             // Set data
  //             var data = [
  //                 {
  //                     Cateogry: "Active",
  //                     member: activeMember,
  //                     bulletSettings: { src: active }
  //                 },
  //                 {
  //                     Cateogry: "Inactive",
  //                     member: inActiveMember,
  //                     bulletSettings: { src: inactive }
  //                 },
  //                 {
  //                     Cateogry: "Dormant",
  //                     member: 1,
  //                     bulletSettings: { src: dormant }
  //                 }
  //             ];

  //             series.bullets.push(function () {
  //                 return am5.Bullet.new(root, {
  //                     locationY: 1,
  //                     sprite: am5.Picture.new(root, {
  //                         templateField: "bulletSettings",
  //                         width: 30,
  //                         height: 30,
  //                         centerX: am5.p50,
  //                         centerY: am5.p50,
  //                         shadowColor: am5.color(0x000000),
  //                         shadowBlur: 4,
  //                         shadowOffsetX: 4,
  //                         shadowOffsetY: 4,
  //                         shadowOpacity: 0.6
  //                     })
  //                 });
  //             });

  //             xAxis.data.setAll(data);
  //             series.data.setAll(data);

  //             // Make stuff animate on load
  //             series.appear(1000);
  //             chart.appear(1000, 100);
  //         });
  //     }
  // }, [activeMember]);
  return (
    <>
      <div className="col-md-4 col-xs-12">
        <p className="chart-heading">Influencer</p>
        {/* <div id="chartdiv1" style={{ width: "100%", height: "250px" }}> */}
        <div id="chart">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={250}
          />
        </div>
      </div>
    </>
  );
}
